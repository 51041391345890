import { customerCsrfPropertyName } from "@novel/shared/utils/appConstants";

// inspired by: https://stackoverflow.com/questions/10730362/get-cookie-by-name
export function getCustomerCsrfToken(getCookieStr?: () => string): string | undefined {
    const cookieStr = getCookieStr
        ? getCookieStr()
        : (typeof document !== "undefined" && `; ${document.cookie}`) || "";

    // Currently, the customer auth token is stored in a cookie
    // so we do not need to read anything from localstorage.
    return getCookie(customerCsrfPropertyName, cookieStr);
}

function getCookie(name: string, cookieStr: string) {
    const parts = cookieStr.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop()?.split(";").shift();
}
