import {
    IOrganizationIntegration,
    IntegrationVendorEnum,
    integrationsUsingPoints,
    integrationsUsingStoreCredit,
} from "@novel/shared/interfaces/Integration";
import { IOrgCustomerRelationship } from "@novel/shared/interfaces/OrgCustomerRelationship";
import { IRewardsTierCreate } from "@novel/shared/interfaces/RewardsTier";
import { IShopifyShopData } from "@novel/shared/interfaces/ShopifyShopData";
import type { FiatCurrencyCode, StoreCreditCurrencyCode } from "@novel/shared/utils/formatMoney";

export function getStoreCreditCurrency({
    orgIntegrations,
    shopifyData,
    orgCustomerRelationship,
    computedCurrencyCode,
    // not using tier at present, but do not want to rule out
    // the possibility of using it in the future (points on one tier, etc.)
    currentRewardsTier,
}: {
    orgIntegrations: IOrganizationIntegration[];
    shopifyData?: IShopifyShopData;
    orgCustomerRelationship?: IOrgCustomerRelationship;
    computedCurrencyCode?: FiatCurrencyCode;
    currentRewardsTier?: IRewardsTierCreate;
}): StoreCreditCurrencyCode {
    // first check if the org has integrations
    const pointSystemIntegration = orgIntegrations.find((integration) =>
        integrationsUsingPoints.includes(integration.integrationId as IntegrationVendorEnum),
    );

    const storeCreditIntegration = orgIntegrations.find((integration) =>
        integrationsUsingStoreCredit.includes(integration.integrationId as IntegrationVendorEnum),
    );

    if (pointSystemIntegration) {
        return "PTS" as StoreCreditCurrencyCode;
    }

    // currently superfiliate integration only supports USD, which means customers will
    // rewarded in USD even if their default currency is different
    if (storeCreditIntegration) {
        return "USD" as StoreCreditCurrencyCode;
    }

    // if customer provided, default to customer currency
    if (orgCustomerRelationship?.customerCurrency) {
        return orgCustomerRelationship.customerCurrency as StoreCreditCurrencyCode;
    }

    const shopEnabledCurrencies = shopifyData?.enabled_presentment_currencies;
    const mainShopCurrency = shopifyData?.currency;

    // if computed currency is set, then potentially use
    if (computedCurrencyCode) {
        // if computed currency enabled list is given, check it, if computed currency is enabled, then use it
        if (shopEnabledCurrencies) {
            if (shopEnabledCurrencies.includes(computedCurrencyCode)) {
                return computedCurrencyCode;
            }
        } else if (!mainShopCurrency) {
            return computedCurrencyCode;
        }
    }

    // if no integrations, customer currency, or computed currency, use the shop currency or fall back to USD
    return mainShopCurrency || ("USD" as StoreCreditCurrencyCode);
}
