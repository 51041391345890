import { NovelCustomerPassSubReducer } from "@customer-pass/redux/customerPassTypedRedux";
import { useNovelCustomerPassSelector } from "@customer-pass/redux/reduxHooks";
import { IOrgPassCustomerRes } from "@novel/shared/interfaces/OrgCustomerRelationship";
import { IOrgHandleCreate } from "@novel/shared/interfaces/OrgHandle";
import { IRewardsTier, IRewardsTierCreate } from "@novel/shared/interfaces/RewardsTier";
import { HOST_WITH_CORRECTED_SUBDOMAINS } from "@novel/shared/utils/appDomainConstants";
import type { FiatCurrencyCode, StoreCreditCurrencyCode } from "@novel/shared/utils/formatMoney";
import { getStoreCreditCurrency } from "@novel/shared/utils/getStoreCreditCurrency";
import capitalize from "lodash/capitalize";

export interface IAuthReducerState {
    isLoadingAuth: boolean;
    authError: string | null;
    loginEmailStatus: null | "requesting" | "success" | "error";
    loginEmailErrorMessage: string | null;
    addEmailForAccountStatus: null | "requesting" | "success" | "error";
    addEmailForAccountErrorMessage: string | null;
    resolvedCustomer: IOrgPassCustomerRes | null;
    serviceWorkerRegistration: ServiceWorkerRegistration | null;
    isLoadingAuthLink: boolean;
    authLink: string | null;
}

const initialState: IAuthReducerState = {
    isLoadingAuth: true,
    authError: null,
    loginEmailStatus: null,
    loginEmailErrorMessage: null,
    addEmailForAccountStatus: null,
    addEmailForAccountErrorMessage: null,
    resolvedCustomer: null,
    serviceWorkerRegistration: null,
    isLoadingAuthLink: true,
    authLink: null,
};

export const authReducer: NovelCustomerPassSubReducer<IAuthReducerState> = (
    state = initialState,
    action,
) => {
    switch (action.type) {
        case "LOADING_RESOLVED_CUSTOMER": {
            return {
                ...state,
                isLoadingAuth: true,
            };
        }

        case "ERROR_LOADING_RESOLVED_CUSTOMER": {
            return {
                ...state,
                isLoadingAuth: false,
                authError: action.payload.errorMessage,
            };
        }

        case "LOADED_RESOLVED_CUSTOMER": {
            return {
                ...state,
                isLoadingAuth: false,
                resolvedCustomer: action.payload.resolvedCustomer,
            };
        }

        case "LOADED_CURRENT_WALLET_PASS": {
            if (!action.payload.currentWalletPass || !state.resolvedCustomer) {
                return state;
            }

            return {
                ...state,
                resolvedCustomer: {
                    ...state.resolvedCustomer,
                    currentWalletPass: action.payload.currentWalletPass,
                },
            };
        }

        case "LOADED_AUTH_LINK": {
            return {
                ...state,
                isLoadingAuthLink: false,
                authLink: action.payload.authLink,
            };
        }

        case "LOG_OUT": {
            return {
                ...state,
                isLoadingAuth: false,
                user: null,
            };
        }

        case "CLEAR_LOGIN_TOKEN_EMAIL_STATE": {
            return {
                ...state,
                loginEmailStatus: null,
                loginEmailErrorMessage: null,
            };
        }

        case "REQUESTING_TOKEN":
        case "REQUESTING_TOKEN_CODE": {
            return {
                ...state,
                loginEmailStatus: "requesting",
                loginEmailErrorMessage: null,
            };
        }

        case "REQUESTED_TOKEN":
        case "REQUESTED_TOKEN_CODE": {
            return {
                ...state,
                loginEmailStatus: "success",
                loginEmailErrorMessage: null,
            };
        }

        case "ERROR_REQUESTING_TOKEN":
        case "ERROR_REQUESTING_TOKEN_CODE": {
            return {
                ...state,
                loginEmailStatus: "error",
                loginEmailErrorMessage: action.payload.errorMessage,
            };
        }

        case "ADDING_EMAIL_FOR_ACCOUNT": {
            return {
                ...state,
                addEmailForAccountStatus: "requesting",
            };
        }
        case "ERROR_ADDING_EMAIL_FOR_ACCOUNT": {
            return {
                ...state,
                addEmailForAccountStatus: "error",
                addEmailForAccountErrorMessage: action.payload.errorMessage,
            };
        }
        case "ADDED_EMAIL_FOR_ACCOUNT": {
            return {
                ...state,
                addEmailForAccountStatus: "success",
                addEmailForAccountErrorMessage: null,
            };
        }
        case "CLEAR_ADD_EMAIL_FOR_ACCOUNT_STATE": {
            return {
                ...state,
                addEmailForAccountStatus: null,
                addEmailForAccountErrorMessage: null,
            };
        }
        case "SET_SERVICE_WORKER_REGISTRATION": {
            return {
                ...state,
                serviceWorkerRegistration: action.payload.serviceWorkerRegistration,
            };
        }

        default: {
            return state;
        }
    }
};

export function useIsSuperUserSelector(): boolean {
    return useNovelCustomerPassSelector(
        (state) => !!state.auth.resolvedCustomer?.novelUser.isSuperUser,
    );
}

export function useIsDemoShopSelector(): boolean {
    return useNovelCustomerPassSelector(
        (state) => !!state.orgData.resolvedOrg && !state.orgData.resolvedOrg?.shopData,
    );
}

export function useRewardsTier(): undefined | IRewardsTier {
    const baseRewardsTier = useNovelCustomerPassSelector(
        (state) => state.orgData.resolvedOrg?.baseRewardsTier,
    );
    const customerCurrentRewardsTier = useNovelCustomerPassSelector(
        (state) => state.auth.resolvedCustomer?.customerCurrentRewardsTier,
    );
    const currentResolvedTier = useNovelCustomerPassSelector(
        (state) => state.passUi.uponInstallRewardsTier,
    );

    return currentResolvedTier || customerCurrentRewardsTier || baseRewardsTier;
}

export function useUserDisplayName(): string {
    return useNovelCustomerPassSelector((state) => {
        const resolvedNovelUser = state.auth.resolvedCustomer?.novelUser;
        if (!resolvedNovelUser) {
            return "• • • • • • • • • •";
        }

        if (resolvedNovelUser?.displayName) {
            return resolvedNovelUser?.displayName;
        }

        const joinedName = [
            capitalize(resolvedNovelUser?.firstName?.trim() || ""),
            capitalize(resolvedNovelUser?.lastName?.trim() || ""),
        ]
            .join(" ")
            .trim();

        if (joinedName) {
            return joinedName;
        }

        return resolvedNovelUser.primaryEmail;
    });
}

export function useResolvedHandle(): IOrgHandleCreate | undefined {
    const customerHandle = useNovelCustomerPassSelector(
        (state) => state.auth.resolvedCustomer?.customerOrgHandle,
    );
    const orgHandle = useNovelCustomerPassSelector(
        (state) => state.orgData.resolvedOrg?.currentOrgHandle,
    );

    return customerHandle || orgHandle;
}

export function useResolvedHost(): string | undefined {
    const resolvedHandle = useResolvedHandle();
    if (resolvedHandle) {
        return `${resolvedHandle.handle}.${HOST_WITH_CORRECTED_SUBDOMAINS}`;
    }
}

export function useResolvedTier(): IRewardsTierCreate | undefined {
    const customerRewardsTier = useNovelCustomerPassSelector(
        (state) => state.auth.resolvedCustomer?.customerCurrentRewardsTier,
    );
    const baseRewardsTier = useNovelCustomerPassSelector(
        (state) => state.orgData.resolvedOrg?.baseRewardsTier,
    );

    if (customerRewardsTier) {
        return customerRewardsTier;
    } else if (baseRewardsTier) {
        return baseRewardsTier;
    }
}

export function useCurrencyCode(): StoreCreditCurrencyCode {
    const computedCurrencyCode = useNovelCustomerPassSelector(
        (state) => state.passUi.computedCurrencyCode,
    );
    const mainShopCurrency = useNovelCustomerPassSelector(
        (state) => state.orgData.resolvedOrg?.shopData?.currency,
    );
    const shopEnabledCurrencies = useNovelCustomerPassSelector(
        (state) => state.orgData.resolvedOrg?.shopData?.enabled_presentment_currencies,
    );

    let resolvedCurrency: StoreCreditCurrencyCode;
    if (!computedCurrencyCode) {
        resolvedCurrency = mainShopCurrency as FiatCurrencyCode;
    } else {
        const countryCurrencyNotEnabled =
            shopEnabledCurrencies &&
            mainShopCurrency &&
            !shopEnabledCurrencies.includes(computedCurrencyCode);
        if (countryCurrencyNotEnabled) {
            resolvedCurrency = mainShopCurrency as FiatCurrencyCode;
        } else {
            resolvedCurrency = computedCurrencyCode;
        }
    }

    const resolvedTier = useResolvedTier();
    const integrations = useNovelCustomerPassSelector(
        (state) => state.orgData.resolvedOrg?.integrations || [],
    );
    const shopifyData = useNovelCustomerPassSelector(
        (state) => state.orgData.resolvedOrg?.shopData,
    );
    const orgCustomer = useNovelCustomerPassSelector(
        (state) => state.auth.resolvedCustomer?.orgCustomer,
    );

    return getStoreCreditCurrency({
        orgIntegrations: integrations,
        shopifyData,
        currentRewardsTier: resolvedTier,
        orgCustomerRelationship: orgCustomer,
        computedCurrencyCode,
    });
}
