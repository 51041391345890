"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.STOREFRONT_API_VERSION_NUMBER = exports.NOVEL_SESSION_TOKEN_HEADER = exports.novelSessionTokenStorageKey = exports.novelSessionJsonStorageKey = exports.novelSessionTokenDataStorageKey = exports.novelStoreCreditBalanceStorageKey = exports.novelStoreCreditStorageKey = exports.novelLinkTypeStorageKey = exports.novelCampaignStorageKey = exports.DEFINED_PASS_APP_ROUTES = exports.NOVEL_SESSION_DATA_PARAM = exports.NOVEL_PASS_CAMPAIGN_ID_PARAM = exports.NOVEL_PASS_LINK_TYPE_PARAM = exports.NOVEL_PASS_CODE_PARAM = exports.NOVEL_PASS_ATTRIBUTION_PARAM = exports.STOREFRONT_LOCAL_STORAGE_KEY = exports.NOVEL_LOAD_STYLE_TAG_ID = exports.NOVEL_HIDDEN_CLASSNAME = exports.NOVEL_TEXT_REPLACED_CLASSNAME = exports.NOVEL_REFERRAL_COUNT_LOADED_CLASSNAME = exports.NOVEL_REFERRAL_COUNT_ATTRIBUTE = exports.NOVEL_REWARDS_BALANCE_LOADED_CLASSNAME = exports.NOVEL_REWARDS_BALANCE_ATTRIBUTE = exports.NOVEL_APPLY_BALANCE_LOADED_CLASSNAME = exports.NOVEL_APPLY_BALANCE_ATTRIBUTE = exports.NOVEL_PASS_INSTALL_LOADED_CLASSNAME = exports.NOVEL_PASS_INSTALL_BUTTON_ATTRIBUTE = exports.NOVEL_AUTH_BUTTON_CLASSNAME = exports.appProxyPrefix = exports.internalShopifyProxyApiPrefix = void 0;
var appDomainConstants_1 = require("@novel/shared/utils/appDomainConstants");
var env_1 = require("@novel/shared/utils/env");
exports.internalShopifyProxyApiPrefix = "/api/public/shopify-proxy";
exports.appProxyPrefix = env_1.NodeEnv === env_1.NodeEnvEnum.production
    ? "/a/novel"
    : "".concat(appDomainConstants_1.apiPrefixedHostWithProtocol).concat(exports.internalShopifyProxyApiPrefix);
// html classnames/attributes
exports.NOVEL_AUTH_BUTTON_CLASSNAME = "__novel-auth-button-trigger-loaded__";
exports.NOVEL_PASS_INSTALL_BUTTON_ATTRIBUTE = "data-novel-pass-install";
exports.NOVEL_PASS_INSTALL_LOADED_CLASSNAME = "__novel-pass-install-button-trigger-loaded__";
exports.NOVEL_APPLY_BALANCE_ATTRIBUTE = "data-apply-rewards-balance";
exports.NOVEL_APPLY_BALANCE_LOADED_CLASSNAME = "__novel-apply-rewards-balance-trigger-loaded__";
exports.NOVEL_REWARDS_BALANCE_ATTRIBUTE = "data-render-rewards-balance";
exports.NOVEL_REWARDS_BALANCE_LOADED_CLASSNAME = "__novel-render-rewards-balance-loaded__";
exports.NOVEL_REFERRAL_COUNT_ATTRIBUTE = "data-render-referral-count";
exports.NOVEL_REFERRAL_COUNT_LOADED_CLASSNAME = "__novel-render-referral-count-loaded__";
exports.NOVEL_TEXT_REPLACED_CLASSNAME = "__novel-text-replaced-while-gated__";
exports.NOVEL_HIDDEN_CLASSNAME = "__novel-hidden-while-gated__";
exports.NOVEL_LOAD_STYLE_TAG_ID = "__novel-style-tag__";
exports.STOREFRONT_LOCAL_STORAGE_KEY = "__novel-storefront-data__";
// query params
exports.NOVEL_PASS_ATTRIBUTION_PARAM = "r"; // used as this is picked up by Shopify's analytics
exports.NOVEL_PASS_CODE_PARAM = "novelPass";
exports.NOVEL_PASS_LINK_TYPE_PARAM = "passLinkType";
exports.NOVEL_PASS_CAMPAIGN_ID_PARAM = "campaignId";
exports.NOVEL_SESSION_DATA_PARAM = "novelSession";
// defined routes in pass
exports.DEFINED_PASS_APP_ROUTES = ["/login", "/oauth", "/share"];
// storage keys
exports.novelCampaignStorageKey = "__novel_campaign__";
exports.novelLinkTypeStorageKey = "__novel_linkType__";
exports.novelStoreCreditStorageKey = "__n_c__";
exports.novelStoreCreditBalanceStorageKey = "__n_b__";
exports.novelSessionTokenDataStorageKey = "__n_s__";
exports.novelSessionJsonStorageKey = "__n_s_j__";
// pass session token storage key (not used in storefront)
exports.novelSessionTokenStorageKey = "__novel_pass_session_token__";
// header
exports.NOVEL_SESSION_TOKEN_HEADER = "x-novel-session-token";
// we will embed this in localStorage to
// track the version number to help ensure it does not get out of date
exports.STOREFRONT_API_VERSION_NUMBER = 1;
