const DEFAULT_RETRY_AMOUNT = 5;
const DEFAULT_DELAY_BETWEEN = 0;

export async function retryWithBackoff<T>(
    createPromise: (currentRetryCount: number) => Promise<T>,
    exitErrorCondition: ((e: Error, currentRetryCount: number) => boolean) | null = null,
    startingDelayBetween: number = DEFAULT_DELAY_BETWEEN,
    retries: number = DEFAULT_RETRY_AMOUNT,
): Promise<T> {
    let currentRetryCount = 0;
    let delayBetween = startingDelayBetween;

    do {
        try {
            return await createPromise(currentRetryCount).then((result) => {
                // handling request retries
                if ((result as any)?.type === "error") {
                    throw result;
                }

                return result;
            });
        } catch (e: any) {
            if (exitErrorCondition && exitErrorCondition(e, currentRetryCount)) {
                const body = e?.response?.body || e?.body;
                if (body) {
                    e.message = `${e.message ? `e.message | ` : ""}${body}`;
                }
                throw e;
            }
            await new Promise((resolve) => setTimeout(resolve, delayBetween));

            // double delay on each retry
            delayBetween *= 2;
        }
    } while (currentRetryCount++ < retries);

    return await createPromise(currentRetryCount);
}
