import type { StringKeyObj } from "@novel/shared/utils/StringKeyObj";

export function parseCookiesToObj(cookiesStr?: string): StringKeyObj {
    if (!cookiesStr) {
        return {};
    }

    return cookiesStr.split(";").reduce((accum: StringKeyObj, s) => {
        const indexOfEquals = s.indexOf("=");
        accum[s.slice(0, indexOfEquals).trim()] = s.slice(indexOfEquals + 1);
        return accum;
    }, {});
}

export function getFromCookies(cookie: string, cookiesStr?: string): string | undefined {
    const cookiesObj = parseCookiesToObj(
        cookiesStr || (typeof document !== "undefined" ? document.cookie : ""),
    );
    return cookiesObj[cookie];
}

export function setCookie({
    name,
    value,
    domain,
    expires,
}: {
    name: string;
    value: string;
    domain?: string;

    // if no expiration set, by default a cookie is deleted when the browser is closed
    expires?: Date | number;
}): void {
    // TODO: handle server-side cookie setting too
    if (typeof document !== "undefined") {
        // if setting to empty string, treat that as a remove
        // also handles edge case of JSON.stringify(undefined)
        // which returns undefined but typescript doesn't catch
        if (typeof value === "undefined" || value === "") {
            removeCookie(name);
        } else {
            document.cookie = `${name}=${value}${
                typeof expires === "undefined"
                    ? ""
                    : `; expires=${(typeof expires === "number"
                          ? new Date(expires)
                          : expires
                      ).toUTCString()}`
            }${!domain ? "" : `; domain=${domain}`}`;
        }
    }
}

export function removeCookie(cookieName: string): void {
    // TODO: handle server-side cookie removal too
    if (typeof document !== "undefined") {
        // expires cookie immediately
        document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`;
    }
}
