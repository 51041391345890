import { AppEnv } from "@novel/shared/utils/env";
import { PassInstallFlow } from "@novel/shared/utils/getInstallPassFlow";
import { loadIntercom as loadIntercomLib } from "next-intercom";
import capitalize from "lodash/capitalize";

export function loadNovelIntercom({
    email,
    phoneNumber,
    userName,
    firstName,
    lastName,
    walletPassInstallFlow,
    isInShopifyAdminIframe,
    orgName,
    domain,
    myShopifyDomain,
    orgCustomerEmail,
    orgPartnerEmail,
    shopOwner,
}: {
    email?: string;
    phoneNumber?: string;
    firstName?: string;
    lastName?: string;
    userName?: string;
    walletPassInstallFlow?: PassInstallFlow;
    isInShopifyAdminIframe?: boolean;
    orgName?: string;
    domain?: string;
    myShopifyDomain?: string;
    orgCustomerEmail?: string;
    orgPartnerEmail?: string;
    shopOwner?: string;
}): Promise<string> | undefined {
    let computedName = [capitalize(firstName || ""), capitalize(lastName || "")].join(" ").trim();
    if (!computedName) {
        computedName = userName || "";
    }

    return loadIntercomLib({
        appId: process.env.NEXT_PUBLIC_INTERCOM_APP_ID,
        env: AppEnv,
        initWindow: true,
        delay: 0,
        email,
        ...(phoneNumber ? { phoneNumber } : {}),
        ...(walletPassInstallFlow ? { walletPassInstallFlow } : {}),
        ...(walletPassInstallFlow ? { walletPassInstallFlow } : {}),
        ...(orgName ? { orgName } : {}),
        ...(domain ? { domain } : {}),
        ...(myShopifyDomain ? { myShopifyDomain } : {}),
        ...(isInShopifyAdminIframe ? { isInShopifyAdminIframe } : {}),
        ...(userName ? { userName } : {}),
        ...(firstName ? { firstName } : {}),
        ...(lastName ? { lastName } : {}),
        ...(computedName ? { name: computedName } : {}),
        ...(orgCustomerEmail ? { orgCustomerEmail } : {}),
        ...(orgPartnerEmail ? { orgPartnerEmail } : {}),
        ...(shopOwner ? { shopOwner } : {}),
        ...(typeof window !== "undefined" ? { userAgent: window.navigator.userAgent } : {}),
    });
}
