import type { ISODateString } from "@novel/shared/interfaces/shared/ISODateString";
import {
    NOVEL_PASS_CAMPAIGN_ID_PARAM,
    NOVEL_PASS_CODE_PARAM,
    NOVEL_SESSION_DATA_PARAM,
} from "@novel/shared/utils/appStorefrontConstants";
import pick from "lodash/pick";
import { parse, stringify } from "qs";

interface HashPropertiesMap {
    [NOVEL_SESSION_DATA_PARAM]?: {
        sessionToken: string;
        linkClickTimeStamp?: ISODateString;
        sessionExpiresAt?: ISODateString;
    };
    [NOVEL_PASS_CAMPAIGN_ID_PARAM]?: string;
    [NOVEL_PASS_CODE_PARAM]?: string;
}

const hashProperties: (keyof HashPropertiesMap)[] = [
    NOVEL_SESSION_DATA_PARAM,
    NOVEL_PASS_CAMPAIGN_ID_PARAM,
    NOVEL_PASS_CODE_PARAM,
];
let hashCache: Partial<HashPropertiesMap> | undefined = undefined;
export function getFromParsedUrlHash<T extends keyof HashPropertiesMap>(
    prop: T,
    deleteHash = true,
): Partial<HashPropertiesMap>[T] {
    if (!hashCache) {
        hashCache = parseUrlHash();
    }

    if (deleteHash && hashCache?.[prop]) {
        removeFromParsedUrlHash(prop);
    }

    return hashCache[prop];
}

export function parseUrlHash(): HashPropertiesMap {
    if (!hashCache) {
        if (typeof window === "undefined") {
            hashCache = {};
        } else {
            const rawParsedUrlHash = parse(window.location.hash.slice(1));

            hashCache = pick(rawParsedUrlHash, hashProperties) as Partial<HashPropertiesMap>;
        }
    }
    return hashCache;
}

export function removeFromParsedUrlHash<T extends keyof HashPropertiesMap>(prop: T): void {
    const parsedUrlHash = parse(window.location.hash.slice(1)) as Partial<HashPropertiesMap>;
    if (Object.prototype.hasOwnProperty.call(parsedUrlHash, prop)) {
        delete parsedUrlHash[prop];
        const newHash = stringify(parsedUrlHash);
        const newHashWithSymbol = newHash ? `#${newHash}` : " ";
        history.replaceState(null, "", newHashWithSymbol);
    }
}
