import { DateTime } from "luxon";

const iso8601Symbol = Symbol("ISO 8601");

/**
 * A string in ISO format (ISO 8601 Extended Format):
 * YYYY-MM-DDTHH:mm:ss.sssZ
 */
export interface ISODateString {
    // this is a hack to make it impossible for this type to be instantiated outside of this file.
    [iso8601Symbol]: never;
}

export const ISODateString = {
    earliestPossibleTime: DateTime.utc(0, 0, 0),
    earliestPossibleTimeString: DateTime.utc(0, 0, 0).toISO() as unknown as ISODateString,

    now(): ISODateString {
        return ISODateString.toString(DateTime.utc());
    },
    toString(inputDate: DateTime | Date): ISODateString {
        if (inputDate instanceof DateTime) {
            return inputDate.toUTC().toISO() as unknown as ISODateString;
        } else {
            return DateTime.fromJSDate(inputDate).toUTC().toISO() as unknown as ISODateString;
        }
    },
    toStringLocale(inputDate: DateTime | Date): ISODateString {
        if (inputDate instanceof DateTime) {
            return inputDate.toLocal().toISO() as unknown as ISODateString;
        } else {
            return DateTime.fromJSDate(inputDate).toLocal().toISO() as unknown as ISODateString;
        }
    },
    fromString(isoDateString: ISODateString): DateTime {
        if (typeof isoDateString === "number") {
            return DateTime.fromMillis(isoDateString).toUTC();
        }

        if (isoDateString instanceof Date) {
            return DateTime.fromJSDate(isoDateString).toUTC();
        }

        if (isoDateString instanceof DateTime) {
            return isoDateString.setZone("UTC");
        }

        return DateTime.fromISO(isoDateString as unknown as string)
            .setZone("UTC")
            .toUTC();
    },
    fromStringLocale(isoDateString: ISODateString): DateTime {
        if (typeof isoDateString === "number") {
            return DateTime.fromMillis(isoDateString).toLocal();
        }

        if (isoDateString instanceof Date) {
            return DateTime.fromJSDate(isoDateString).toLocal();
        }

        if (isoDateString instanceof DateTime) {
            return isoDateString.toLocal();
        }
        return DateTime.fromISO(isoDateString as unknown as string, { setZone: true }).toLocal();
    },
    equals(base: ISODateString | Date | DateTime, other: ISODateString | Date | DateTime): boolean {
        const baseDateTime = ISODateString.fromString(base as ISODateString)
            .toJSDate()
            .getTime();
        const otherDateTime = ISODateString.fromString(other as ISODateString)
            .toJSDate()
            .getTime();
        return baseDateTime === otherDateTime;
    },
    // simple convert and check if dates match for graph very critical
    equalsDateString(
        base: ISODateString | Date | DateTime,
        other: ISODateString | Date | DateTime,
    ): boolean {
        const baseDateTime = ISODateString.fromString(base as ISODateString)
            .toJSDate()
            .toDateString();
        const otherDateTime = ISODateString.fromString(other as ISODateString)
            .toJSDate()
            .toDateString();
        return baseDateTime === otherDateTime;
    },
    isBefore(
        base: ISODateString | Date | DateTime,
        other: ISODateString | Date | DateTime,
    ): boolean {
        const baseDateTime = ISODateString.fromString(base as ISODateString)
            .toJSDate()
            .getTime();
        const otherDateTime = ISODateString.fromString(other as ISODateString)
            .toJSDate()
            .getTime();
        return baseDateTime < otherDateTime;
    },
    isAfter(
        base: ISODateString | Date | DateTime,
        other: ISODateString | Date | DateTime,
    ): boolean {
        const baseDateTime = ISODateString.fromString(base as ISODateString)
            .toJSDate()
            .getTime();
        const otherDateTime = ISODateString.fromString(other as ISODateString)
            .toJSDate()
            .getTime();
        return baseDateTime > otherDateTime;
    },
    isInFuture(date: ISODateString | Date | DateTime): boolean {
        return ISODateString.isAfter(date, new Date());
    },
    isInPast(date: ISODateString | Date | DateTime): boolean {
        return ISODateString.isBefore(date, new Date());
    },
};
