import { useEffect } from "react";

export function useAsyncEffect<T>(
    promiseGen: () => Promise<T>,
    callback: (value: T) => void,
    dependenciesArray: any[],
): void {
    useEffect(() => {
        let isCancelled = false;
        promiseGen().then((resultValue: T) => {
            if (!isCancelled) {
                callback(resultValue);
            }
        });

        return () => {
            isCancelled = true;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, dependenciesArray);
}
