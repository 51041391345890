import React, { useCallback, useContext } from "react";
import { ThemeProvider } from "styled-components";
import merge from "lodash/merge";
import { common } from "./common";
import { dark } from "./dark";
import { light } from "./light";
export const ThemeContext = React.createContext({
  theme: "light",
  toggle: () => {
    void 0;
  }
});
export const useTheme = () => {
  const {
    theme,
    toggle
  } = useContext(ThemeContext);
  return {
    theme: merge({}, common, theme === "light" ? light : dark),
    toggle,
    themeName: theme
  };
};
export const NovelProvider: React.FC = ({
  children
}) => {
  const [theme, setTheme] = React.useState<"light" | "dark">("light");
  const toggle = useCallback(() => {
    setTheme(theme => theme === "light" ? "dark" : "light");
  }, []);
  const values = React.useMemo(() => ({
    theme,
    toggle
  }), [toggle, theme]);
  const currentThemeObj = merge({}, common, theme === "light" ? light : dark);
  return <ThemeContext.Provider value={values}>
            <ThemeProvider theme={currentThemeObj}>{children}</ThemeProvider>
        </ThemeContext.Provider>;
};