"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.apiPrefixedHostWithProtocol = exports.appPrefixedHostWithProtocol = exports.oauthPrefixedHostWithProtocol = exports.apiPrefixedHost = exports.appPrefixedHost = exports.oauthPrefixedHost = exports.HOST_WITH_CORRECTED_SUBDOMAINS = exports.HOST_WITHOUT_PROTOCOL = void 0;
var env_1 = require("@novel/shared/utils/env");
// the below is messy but definitely necessary to get all env URLs correct on front end and back end
exports.HOST_WITHOUT_PROTOCOL = process.env.NEXT_PUBLIC_HOST.replace("https://", "");
// in development we have an extra subdomain we don't want for pass install links
exports.HOST_WITH_CORRECTED_SUBDOMAINS = env_1.AppEnv === env_1.AppEnvEnum.development
    ? exports.HOST_WITHOUT_PROTOCOL.replace("novel-dev.", "")
    : env_1.AppEnv === env_1.AppEnvEnum.staging
        ? // cheating - it's a bit impossible to get this right in staging
            // because of the build process being the same for staging and prod
            "staging.novel.com"
        : exports.HOST_WITHOUT_PROTOCOL;
exports.oauthPrefixedHost = "".concat(env_1.NodeEnv === env_1.NodeEnvEnum.production ? "wallet." : "novel-dev.").concat(exports.HOST_WITH_CORRECTED_SUBDOMAINS);
exports.appPrefixedHost = "".concat(env_1.NodeEnv === env_1.NodeEnvEnum.production ? "app." : "").concat(env_1.AppEnv === env_1.AppEnvEnum.staging && !exports.HOST_WITHOUT_PROTOCOL.includes("staging.") ? "staging." : "").concat(exports.HOST_WITHOUT_PROTOCOL);
exports.apiPrefixedHost = "".concat(env_1.NodeEnv === env_1.NodeEnvEnum.production ? "api." : "").concat(env_1.AppEnv === env_1.AppEnvEnum.staging && !exports.HOST_WITHOUT_PROTOCOL.includes("staging.") ? "staging." : "").concat(exports.HOST_WITHOUT_PROTOCOL);
exports.oauthPrefixedHostWithProtocol = "https://".concat(exports.oauthPrefixedHost);
exports.appPrefixedHostWithProtocol = "https://".concat(exports.appPrefixedHost);
exports.apiPrefixedHostWithProtocol = "https://".concat(exports.apiPrefixedHost);
