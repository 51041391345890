import noop from "lodash/noop";
import { toast, Id, TypeOptions, ToastPosition } from "react-toastify";

let MOST_RECENT_ID: Id | undefined = undefined;

export function dismissToast(): void {
    if (MOST_RECENT_ID) {
        return toast.dismiss(MOST_RECENT_ID);
    }
}

export function loadingToast(loadingContent: string): {
    onSuccessToast: (successContent: string, toastDuration?: number | undefined) => Id;
    onErrorToast: (errorContent: string, toastDuration?: number | undefined) => Id;
} {
    const hadId = !!MOST_RECENT_ID;

    let resolve: any;
    let reject: any;
    toast.promise<
        {
            content: string;
            toastDuration?: number;
        },
        {
            content: string;
            toastDuration?: number;
        },
        {
            content: string;
            toastDuration?: number;
        }
    >(
        new Promise<{ content: string; toastDuration?: number }>((_resolve, _reject) => {
            resolve = _resolve;
            reject = _reject;
        }),
        {
            pending: {
                render: ({ toastProps }) => {
                    MOST_RECENT_ID = toastProps.toastId;
                    return loadingContent;
                },
                hideProgressBar: false,
            },
            success: {
                render({
                    data: { content = "", toastDuration = TOAST_DURATION } = {},
                    toastProps,
                    closeToast,
                }) {
                    clearTimeout(toastTimeout as any);
                    if (closeToast) {
                        toastTimeout = setTimeout(() => {
                            if (toastProps.toastId === MOST_RECENT_ID) {
                                MOST_RECENT_ID = undefined;
                                closeToast();
                            }
                        }, toastDuration);
                    }
                    return content || "";
                },
            },
            error: {
                render({
                    data: { content = "", toastDuration = TOAST_DURATION } = {},
                    toastProps,
                    closeToast,
                }) {
                    clearTimeout(toastTimeout as any);
                    if (closeToast) {
                        toastTimeout = setTimeout(() => {
                            if (toastProps.toastId === MOST_RECENT_ID) {
                                MOST_RECENT_ID = undefined;
                                closeToast();
                            }
                        }, toastDuration);
                    }
                    return content || "";
                },
            },
        },
        {
            ...(!hadId
                ? {}
                : {
                      updateId: MOST_RECENT_ID,
                  }),
            position: DEFAULT_TOAST_POSITION,
            className: `${TOAST_CLASSNAME} rotateX animated`,
            bodyClassName: TOAST_BODY_CLASSNAME,
            hideProgressBar: true,
        },
    );

    return {
        onSuccessToast: (
            successContent: string,
            toastDuration: number | undefined = TOAST_DURATION,
        ) => {
            return resolve({
                content: successContent,
                toastDuration,
            });
        },
        onErrorToast: (
            errorContent: string,
            toastDuration: number | undefined = TOAST_DURATION,
        ) => {
            return reject({
                content: errorContent,
                toastDuration,
            });
        },
    };
}

const TOAST_DURATION = 5000;
export function successToast({
    content,
    toastDuration = TOAST_DURATION,
    toastId = MOST_RECENT_ID,
    placement,
}: {
    content: string;
    toastDuration?: number;
    toastId?: Id;
    placement?: ToastPosition;
}): Id {
    return baseToast({
        type: "success",
        toastId,
        content,
        isLoading: false,
        duration: toastDuration || TOAST_DURATION,
        placement,
    });
}

export function errorToast({
    content,
    toastDuration = TOAST_DURATION,
    toastId = MOST_RECENT_ID,
    placement,
}: {
    content: string;
    toastDuration?: number;
    toastId?: Id;
    placement?: ToastPosition;
}): Id {
    return baseToast({
        type: "error",
        toastId,
        content,
        isLoading: false,
        duration: toastDuration || TOAST_DURATION,
        placement,
    });
}

export function infoToast({
    content,
    toastDuration = TOAST_DURATION,
    toastId = MOST_RECENT_ID,
    placement,
}: {
    content: string;
    toastDuration?: number;
    toastId?: Id;
    placement?: ToastPosition;
}): Id {
    return baseToast({
        type: "info",
        toastId,
        content,
        isLoading: false,
        duration: toastDuration || TOAST_DURATION,
        placement,
    });
}

const DEFAULT_TOAST_POSITION: ToastPosition = "bottom-center";
const TOAST_CLASSNAME = "novel-toast";
const TOAST_BODY_CLASSNAME = "novel-toast-body";
let toastTimeout: NodeJS.Timeout | number;
function baseToast({
    type,
    toastId,
    content,
    isLoading,
    duration,
    placement,
}: {
    type: TypeOptions;
    toastId?: Id;
    content: string;
    isLoading: boolean;
    duration?: number;
    placement?: ToastPosition;
}): Id {
    let _toastId = toastId;
    let unsubscribe = noop;
    if (!_toastId) {
        _toastId = MOST_RECENT_ID = toast(content, {
            type,
            position: placement || DEFAULT_TOAST_POSITION,
            className: TOAST_CLASSNAME,
            bodyClassName: TOAST_BODY_CLASSNAME,
            isLoading,
            hideProgressBar: true,
            ...(isLoading ? { autoClose: TOAST_DURATION * 2 } : {}),
            ...(duration ? { autoClose: duration } : {}),
        });
        // here we manage maintaining one active toast at a time
        unsubscribe = toast.onChange((payload) => {
            switch (payload.status) {
                case "removed": {
                    if (MOST_RECENT_ID === _toastId) {
                        MOST_RECENT_ID = undefined;
                    }
                    break;
                }
            }
        });
    } else {
        clearTimeout(toastTimeout as any);
        toast.update(_toastId, {
            render: content,
            type,
            position: placement || DEFAULT_TOAST_POSITION,
            className: `${TOAST_CLASSNAME} rotateX animated`,
            bodyClassName: TOAST_BODY_CLASSNAME,
            ...(isLoading ? { autoClose: TOAST_DURATION * 2 } : {}),
            ...(duration ? { autoClose: duration } : {}),
        });
    }

    if (duration) {
        toastTimeout = setTimeout(() => {
            if (_toastId === MOST_RECENT_ID) {
                toast.dismiss(_toastId);
                MOST_RECENT_ID = undefined;
            }
            unsubscribe();
        }, duration);
    }

    return _toastId;
}
