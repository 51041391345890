import React from "react";
import type { NextComponentType, NextPageContext } from "next";
import NextApp, { AppProps as DefaultAppProps, AppContext } from "next/app";
import Script from "next/script";
import { Provider } from "react-redux";
import { NovelProvider } from "@customer-pass/definitions/styled-components";
import { wrapper } from "@customer-pass/redux/store";
import type { IncomingMessage } from "http";
import isNil from "lodash/isNil";
import omitBy from "lodash/omitBy";
import size from "lodash/size";
import Head from "next/head";
import Favicon from "@customer-pass-public/favicon.ico";
import "../styles/global.scss";
import { InitContainer } from "@customer-pass/components/InitContainer";
import { GlobalErrorBoundary } from "@novel/shared/components/GlobalErrorBoundary";
import { CUSTOMER_HEADERS, customerCsrfPropertyName, PUBLIC_HEADERS, NOVEL_PASS_USER_EMAIL_PARAM } from "@novel/shared/utils/appConstants";
import { novelSessionTokenStorageKey, NOVEL_PASS_LINK_TYPE_PARAM, NOVEL_PASS_CAMPAIGN_ID_PARAM, NOVEL_PASS_CODE_PARAM, NOVEL_SESSION_DATA_PARAM, NOVEL_SESSION_TOKEN_HEADER, NOVEL_PASS_ATTRIBUTION_PARAM } from "@novel/shared/utils/appStorefrontConstants";
import { SafePromise } from "@novel/shared/utils/SafePromise";
import { TypedCustomerApi } from "@novel/shared/typedCustomerApi/TypedCustomerRoutes";
import { getCustomerCsrfToken } from "@novel/shared/utils/getCustomerCsrfToken";
import { useNovelCustomerPassSelector } from "@customer-pass/redux/reduxHooks";
import { useRewardsTier, useIsDemoShopSelector } from "@customer-pass/redux/reducers/auth";
import { stringify as qsStringify } from "qs";
import { getValidUrl } from "@novel/shared/utils/validURL";
import { parseUrlHash } from "@novel/shared/utils/parsedUrlHash";
import { retryWithBackoff } from "@novel/shared/utils/retryWithBackoff";
import { AppEnv, AppEnvEnum } from "@novel/shared/utils/env";
import { getFromCookies, setCookie } from "@novel/shared/utils/cookieUtils";
import { HOST_WITH_CORRECTED_SUBDOMAINS } from "@novel/shared/utils/appDomainConstants";
import { updateUrlQueryParams } from "@novel/shared/utils/updateUrlQueryParams";
import { IOrgResolvedFromHandle } from "@novel/shared/interfaces/Organization";
import { INovelCustomerPassState } from "@customer-pass/redux/reducers";
import { copyToClipboard } from "@novel/shared/components/CopyToClipBoardCmp";
interface PassAppProps extends DefaultAppProps {
  readonly isOAuthRoute?: boolean;
  readonly Component: NextComponentType<NextPageContext, never, {
    props: any;
  }> & {
    isPublicRoute?: boolean;
    isSuperUserRoute?: boolean;
    isLoggedOutRoute?: boolean;
  };
}
interface PassAppContext extends AppContext {
  Component: NextComponentType<NextPageContext<any>, {}, {}> & {
    isPublicRoute?: boolean;
    isSuperUserRoute?: boolean;
    isLoggedOutRoute?: boolean;
  };
}
const sanitizeUrl = (url: string) => {
  if (url.includes("www.")) {
    url = url.replace("www.", "");
  }
  if (url.startsWith("http://")) {
    url = url.replace("http://", "");
  }
  if (!url.startsWith("https://")) {
    return `https://${url}`;
  }
  return url;
};
export default function App({
  isOAuthRoute,
  Component,
  ...rest
}: PassAppProps): JSX.Element {
  const {
    store,
    props
  } = wrapper.useWrappedStore(rest);
  const castedProps = props as PassAppProps;
  const hashProps = parseUrlHash();
  if (typeof document !== "undefined" && props?.query?.sessionToken) {
    // max expiration in Chrome - this is just used to associate a phone number so it's fine
    const oneDay = 60 * 60 * 24 * 1000;
    const jwtExpiresIn = oneDay * 400;
    const date = new Date();
    date.setTime(date.getTime() + jwtExpiresIn); // 24 hours from now

    setCookie({
      name: novelSessionTokenStorageKey,
      value: props.query.sessionToken,
      expires: date,
      domain: HOST_WITH_CORRECTED_SUBDOMAINS
    });
  }
  return <GlobalErrorBoundary
  // loading toast module is throwing error on oauth page
  noToast={isOAuthRoute}>
            <NovelProvider>
                <Provider store={store}>
                    <HeadCmp isOAuthRoute={isOAuthRoute} />
                    <InitContainer isSuperUserRoute={Component.isSuperUserRoute} isLoggedOutRoute={Component.isLoggedOutRoute} isPublicRoute={Component.isPublicRoute}>
                        <Component props={{
            ...castedProps.pageProps,
            ...castedProps.router.query,
            ...hashProps
          }} />
                    </InitContainer>
                    {!isOAuthRoute && <div id="pass-modal-container" />}
                </Provider>
                {!isOAuthRoute && <div id="ua-toaster" style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: 35,
        height: 35,
        zIndex: 999999
      }} onClick={async () => {
        copyToClipboard(window.navigator.userAgent);
        const UAParser = (await retryWithBackoff(() => import("ua-parser-js"))).default;
        const {
          infoToast
        } = await retryWithBackoff(() => import("@novel/shared/utils/toastUtils"));
        infoToast({
          content: `copied: ${(() => {
            const parser = new UAParser();
            return parser.getBrowser().name;
          })()}: ${window.navigator.userAgent}`
        });
      }} />}
                {AppEnv === AppEnvEnum.production && <Script type="text/javascript">
                        {`(function(c,l,a,r,i,t,y){
                                c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                                t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                                y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
                            })(window, document, "clarity", "script", "k2a4w2cxm5");`}
                    </Script>}
            </NovelProvider>
        </GlobalErrorBoundary>;
}
function HeadCmp({
  isOAuthRoute
}: {
  readonly isOAuthRoute?: boolean;
}): JSX.Element {
  const org = useNovelCustomerPassSelector(state => state.orgData.resolvedOrg?.org);
  const rewardsConfig = useNovelCustomerPassSelector(state => state.orgData.resolvedOrg?.rewardsConfig);
  const isDemo = useIsDemoShopSelector();
  const orgBrand = useNovelCustomerPassSelector(state => state.orgData.resolvedOrg?.brand);
  const passOrgNameOverride = useNovelCustomerPassSelector(state => state.orgData.resolvedOrg?.rewardsConfig.passOrgNameOverride);
  const baseRewardsTier = useNovelCustomerPassSelector(state => state.orgData.resolvedOrg?.baseRewardsTier);
  const customerCurrentRewardsTier = useNovelCustomerPassSelector(state => state.auth.resolvedCustomer?.customerCurrentRewardsTier);
  const rewardsTier = useRewardsTier();
  const passInstallLink = useNovelCustomerPassSelector(state => state.auth.resolvedCustomer?.passInstallLink);
  const walletPassInstallFlow = useNovelCustomerPassSelector(state => state.passUi.walletPassInstallFlow);
  const siteTitle = rewardsConfig?.customShareTitle || (customerCurrentRewardsTier?.tierName && !isOAuthRoute ? `${passOrgNameOverride || org?.orgName} | ${isDemo ? "Demo Wallet Pass" : customerCurrentRewardsTier?.tierName}` : `Get Your ${isOAuthRoute || !passOrgNameOverride && !org?.orgName ? "" : `${passOrgNameOverride || org?.orgName} `}Wallet Pass!`);
  const siteDescription = rewardsConfig?.customSiteDescription || `Your ${passOrgNameOverride || org?.orgName} Wallet Pass awaits!  Get early access to products and exclusive rewards.`;

  // use brand favicon, fall back to logo image
  // TODO: only use logo image if it's a square
  const siteFavicon = orgBrand?.favicon || rewardsTier?.iconImageUrl || Favicon.src;

  // use brand OG/Twitter images if they exist, fall back to strip image
  const siteOgImage = orgBrand?.ogImageUrl || orgBrand?.twitterImageUrl || customerCurrentRewardsTier?.stripImageUrl || baseRewardsTier?.stripImageUrlx3 || baseRewardsTier?.stripImageUrlx2 || baseRewardsTier?.stripImageUrl || "https://cdn.jsdelivr.net/gh/uzairbangee/static-image/novel.jpg";
  const siteTwitterImage = orgBrand?.twitterImageUrl || siteOgImage;
  return <Head>
            <title>{siteTitle}</title>

            {passInstallLink && (
    // optimizing by initiating load of the pass install
    // flow before the javascript triggers this
    walletPassInstallFlow === "ios-phone" ?
    // ios link is our server, can be prefetch
    <link rel="prefetch" href={passInstallLink} /> :
    // android link is a third party server, must be preconnect
    <link rel="preconnect" href={passInstallLink} />)}

            <meta name="viewport" content="width=device-width, initial-scale=1, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no" />

            <link rel="shortcut icon" type="image/png" href={siteFavicon} />
            <link rel="canonical" href="https://www.novel.com" />

            <meta name="twitter:title" content={siteTitle} />
            <meta name="twitter:description" content={siteDescription} />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:image" content={siteTwitterImage} />

            <meta name="og:title" content={siteTitle} />
            <meta name="og:description" content={siteDescription} />
            <meta name="og:type" content="website" />
            <meta name="og:image" content={siteOgImage} />

            <style>{`
                body {
                    background-color: #000 !important;
                }
            `}</style>
        </Head>;
}
App.getInitialProps = wrapper.getInitialAppProps(store =>
// eslint-disable-next-line sonarjs/cognitive-complexity
async function getServerSideProps(context: PassAppContext) {
  const host = context.ctx.req?.headers.host;
  const baseUrl = host && `https://${host}` || "";
  const isOAuthRoute = context.router.asPath.includes("/oauth");
  const ctxPromise = new SafePromise(NextApp.getInitialProps(context));
  if (isOAuthRoute) {
    const ctx = await ctxPromise;
    return {
      pageProps: {
        ...ctx.pageProps,
        isOAuth: isOAuthRoute
      },
      initialState: store.getState()
    };
  }
  const staticRoutes = ["login", "oauth/google", "auth", "share", "not-found"];
  const sourceTag = context.router.query?.sourceTag && !staticRoutes.includes(Array.isArray(context.router.query.sourceTag) ? context.router.query.sourceTag[0] : context.router.query.sourceTag) ? context.router.query?.sourceTag as string : undefined;
  const referralCode = typeof context?.ctx?.query?.referralCode === "string" ? context.ctx.query.referralCode : typeof context?.ctx?.query["props[referralCode]"] === "string" ? context?.ctx?.query["props[referralCode]"] : undefined;
  const redirectUrl = typeof context?.ctx?.query?.redirectUrl === "string" ? context?.ctx?.query?.redirectUrl : undefined;
  const sanitizedRedirectUrl = redirectUrl ? sanitizeUrl(redirectUrl) : undefined;
  const passLinkType = typeof context?.ctx.query?.[NOVEL_PASS_LINK_TYPE_PARAM] === "string" && context.ctx.query[NOVEL_PASS_LINK_TYPE_PARAM] || undefined;
  const userEmail = typeof context?.ctx?.query?.[NOVEL_PASS_USER_EMAIL_PARAM] === "string" && context.ctx.query[NOVEL_PASS_USER_EMAIL_PARAM] || undefined;
  const campaignId = typeof context?.ctx?.query?.[NOVEL_PASS_CAMPAIGN_ID_PARAM] === "string" && context.ctx.query[NOVEL_PASS_CAMPAIGN_ID_PARAM] || undefined;
  const novelPassCode = typeof context?.ctx.query?.[NOVEL_PASS_CODE_PARAM] === "string" && context.ctx.query[NOVEL_PASS_CODE_PARAM] || undefined;
  const csrfToken = getCustomerCsrfToken(() => !context.ctx.req?.headers.cookie ? "" : context.ctx.req.headers.cookie) || "";
  const serverTypedCustomerApi = getServerTypedCustomerApi({
    baseUrl,
    csrfToken
  }, context.ctx.req);
  if (context.ctx.req && !store.getState().auth.resolvedCustomer) {
    await store.dispatch((await retryWithBackoff(() => import("@customer-pass/redux/actionCreators/session"))).loadSession(serverTypedCustomerApi, {
      referralCode,
      sourceTag,
      ...(passLinkType ? {
        includeSessionToken: "true"
      } : {})
    }) as any // it's unaware of redux thunk
    )?.catch((e: any) => {
      console.error("error loading session", e?.message || e);
    });
  }
  const {
    auth: {
      resolvedCustomer,
      authLink
    },
    orgData: {
      resolvedOrg
    }
  } = store.getState();
  const qsHashString = (() => {
    const qsObj = {
      ...(!resolvedCustomer?.sessionToken ? {} : {
        [NOVEL_SESSION_DATA_PARAM]: {
          sessionToken: resolvedCustomer.sessionToken,
          linkClickTimeStamp: +new Date()
        }
      }),
      ...(!novelPassCode ? {} : {
        [NOVEL_PASS_CODE_PARAM]: novelPassCode
      })
    };
    return !size(qsObj) ? "" : `#${qsStringify(qsObj)}`;
  })();
  console.log("creating attribution", {
    userEmail,
    passLinkType,
    sanitizedRedirectUrl
  });

  // will be passed to the storefront for order attribution
  const customerEventId = await createAttributionEvent(serverTypedCustomerApi, {
    userEmail,
    passLinkType,
    redirectUrl: sanitizedRedirectUrl,
    campaignId
  });
  const multiPassUrl = await getMultipassUrl({
    resolvedOrg,
    userEmail,
    customerEventId,
    redirectUrl: sanitizedRedirectUrl,
    urlHashStr: qsHashString
  });
  if (context.router.pathname === "/share") {
    await serverTypedCustomerApi.postReq("/api/customer/create-attribution", {
      reqBody: {
        email: userEmail as string,
        redirectUrl: sanitizedRedirectUrl || "",
        [NOVEL_PASS_LINK_TYPE_PARAM]: "referral link"
      }
    });
  } else if (!store.getState().orgData.isLoadingOrganization) {
    const isRedirecting = redirectIfNeeded(context, store.getState(), {
      redirectUrl: sanitizedRedirectUrl,
      referralCode,
      multiPassUrl,
      customerEventId,
      novelPassCode,
      userEmail,
      sourceTag,
      qsHashString
    });
    if (!isRedirecting && resolvedCustomer && userEmail) {
      const fingerprintInfo: {
        visitorId: string;
        browserName: string;
        ip: string;
      } | undefined = (() => {
        const fingerprintStr = getFromCookies("fingerprint", context.ctx.req?.headers.cookie);
        return fingerprintStr && fingerprintStr !== "undefined" && JSON.parse(fingerprintStr) || undefined;
      })();
      if (fingerprintInfo?.visitorId) {
        await serverTypedCustomerApi.postReq("/api/customer/fingerprint", {
          reqBody: {
            email: userEmail as string,
            fingerprintId: fingerprintInfo.visitorId,
            browser: fingerprintInfo.browserName,
            ipAddress: fingerprintInfo.ip
          }
        });
      }
    }

    // in case the user is on desktop or
    // an unsupported browser (which can only be detected accurately from front end)
    // we populate the auth link
    await (!isRedirecting && !!resolvedCustomer && !authLink && new SafePromise(retryWithBackoff(async () => store.dispatch((await retryWithBackoff(() => import("@customer-pass/redux/actionCreators/auth/authLink"))).populateAuthLink(serverTypedCustomerApi, context.ctx.query) as any // "store.dispatch" type is unaware of redux thunk
    ), null, 0, 2 // 2 retries
    )?.catch((e: any) => {
      console.error("error loading auth link", e?.message || e);
    })));
  }
  return {
    pageProps: (await ctxPromise).pageProps,
    initialState: store.getState()
  };
});
function redirectUserThroughHandle(ctx: NextPageContext<any>, location: string, handle: string): boolean {
  return redirectUser(ctx, `https://${handle}.${HOST_WITH_CORRECTED_SUBDOMAINS}${location}`);
}
function redirectUser(ctx: NextPageContext<any>, location: string): boolean {
  if (ctx.req && ctx.res) {
    ctx.res.writeHead(302, {
      Location: location
    });
    ctx.res.end();
    return true;
  }
  return false;
}
function getServerTypedCustomerApi({
  baseUrl,
  csrfToken
}: {
  baseUrl: string;
  csrfToken: string;
}, req?: IncomingMessage): TypedCustomerApi {
  // can be used to pick the correct cookie
  let sessionToken: string | undefined = undefined;
  if (req?.url) {
    try {
      const parsedUrl = new URL(req.url, baseUrl);
      sessionToken = parsedUrl.searchParams.get("sessionToken") || undefined;
    } catch (e) {
      console.error(e);
    }
  }
  const headers: HeadersInit = {
    ...(!req?.headers ? {} : omitBy({
      ...req.headers
    }, isNil)),
    ...(!csrfToken ? {} : {
      [customerCsrfPropertyName]: csrfToken
    }),
    ...(!sessionToken ? {} : {
      [NOVEL_SESSION_TOKEN_HEADER]: sessionToken
    }),
    ...(typeof window !== "undefined" || !process.env.PUBLIC_API_INTERNAL_SECRET ? {} : {
      [PUBLIC_HEADERS.INTERNAL_CALL]: process.env.PUBLIC_API_INTERNAL_SECRET!
    })
  };
  const {
    "cf-iplatitude": rawRequestLatitude,
    "cf-iplongitude": rawRequestLongitude
  } = headers;
  if (rawRequestLatitude && rawRequestLongitude) {
    headers[CUSTOMER_HEADERS.CF_IPLATITUDE] = rawRequestLatitude;
    headers[CUSTOMER_HEADERS.CF_IPLONGITUDE] = rawRequestLongitude;
  }
  return new TypedCustomerApi(baseUrl, [(endpoint, requestConfig) => {
    return {
      ...requestConfig,
      headers
    };
  }]);
}
async function getMultipassUrl({
  resolvedOrg,
  userEmail,
  customerEventId,
  redirectUrl,
  urlHashStr
}: {
  resolvedOrg?: IOrgResolvedFromHandle | null;
  userEmail?: string;
  customerEventId?: string;
  redirectUrl?: string;
  urlHashStr?: string;
}): Promise<string | undefined> {
  if (resolvedOrg?.org.multipassSecret && resolvedOrg.shopData?.domain && redirectUrl && userEmail) {
    const {
      Multipass
    } = await retryWithBackoff(() => import("multipass-js"));
    return new Multipass(resolvedOrg.org.multipassSecret).withCustomerData({
      email: userEmail
    }).withDomain(resolvedOrg.shopData.domain).withRedirect(updateUrlQueryParams(!customerEventId ? {} : {
      [NOVEL_PASS_ATTRIBUTION_PARAM]: customerEventId
    }, `${redirectUrl}${urlHashStr || ""}`)).url();
  }
}
function createAttributionEvent(serverTypedCustomerApi: TypedCustomerApi, {
  userEmail,
  passLinkType,
  redirectUrl,
  campaignId
}: {
  userEmail?: string;
  passLinkType?: string;
  redirectUrl?: string;
  campaignId?: string;
}) {
  if (userEmail && passLinkType && redirectUrl) {
    return serverTypedCustomerApi.postReq("/api/customer/create-attribution", {
      reqBody: {
        email: userEmail,
        [NOVEL_PASS_CAMPAIGN_ID_PARAM]: typeof campaignId === "string" ? campaignId : undefined,
        redirectUrl,
        [NOVEL_PASS_LINK_TYPE_PARAM]: passLinkType
      }
    }).then(res => {
      if (res.type === "success") {
        return res.body.customerEventId;
      }
    });
  }
}
function redirectIfNeeded(context: PassAppContext, state: INovelCustomerPassState, {
  redirectUrl,
  referralCode,
  multiPassUrl,
  customerEventId,
  novelPassCode,
  userEmail,
  sourceTag,
  qsHashString
}: {
  redirectUrl?: string;
  referralCode?: string;
  multiPassUrl?: string;
  customerEventId?: string;
  novelPassCode?: string;
  userEmail?: string;
  sourceTag?: string;
  qsHashString?: string;
}): boolean {
  const {
    auth: {
      resolvedCustomer,
      isLoadingAuth
    },
    orgData: {
      resolvedOrg
    }
  } = state;
  const {
    isSuperUserRoute,
    isLoggedOutRoute,
    isPublicRoute
  } = context.Component;
  const isOnNotFoundPath = context.router.asPath?.includes("/not-found");
  const userNotAuthorized = isSuperUserRoute && resolvedCustomer && !resolvedCustomer.novelUser.isSuperUser;
  const orgIsNotFound = !resolvedOrg || !!(userNotAuthorized && !context.Component.isPublicRoute);
  const host = context.ctx.req?.headers.host;
  const handle = host?.replace(`.${HOST_WITH_CORRECTED_SUBDOMAINS}`, "");
  const baseUrl = host && `https://${host}` || "";
  if (orgIsNotFound) {
    if (!isOnNotFoundPath && handle) {
      return redirectUserThroughHandle(context.ctx, "/not-found", handle);
    }
    return false;
  }
  const isSuperfiliateLink = redirectUrl?.includes("superfiliate-reward");
  const userNotLoggedIn = !resolvedCustomer && !isLoadingAuth;
  if (userNotLoggedIn) {
    const currentOrgHandle = resolvedOrg.currentOrgHandle.handle;
    const isOnLoggedOutPath = context.router.asPath === "/login";
    const isPassAuthPath = context.router.asPath.includes("/auth");
    if ((!isPublicRoute || isPassAuthPath) && !isLoggedOutRoute && !isOnLoggedOutPath) {
      /* 
          We dont want to add params to the redirectURL if they have mutlipass enabled at the logged out state
      */
      let loggedOutRedirectUrlWithMultipassEnabled: string = "";
      if (isPassAuthPath) {
        loggedOutRedirectUrlWithMultipassEnabled = `${baseUrl}${context.router.basePath}${context.router.asPath}`;
      }
      return redirectUserThroughHandle(context.ctx, `/login${qsStringify({
        referralCode,
        redirectUrl: (isSuperfiliateLink || !multiPassUrl) && redirectUrl ? updateUrlQueryParams(!customerEventId ? {} : {
          [NOVEL_PASS_ATTRIBUTION_PARAM]: customerEventId
        }, `${redirectUrl}`) : loggedOutRedirectUrlWithMultipassEnabled ? loggedOutRedirectUrlWithMultipassEnabled : undefined,
        [NOVEL_PASS_CODE_PARAM]: novelPassCode,
        userEmail,
        sourceTag
      }, {
        addQueryPrefix: true
      })}`, currentOrgHandle);
    } else if (handle && handle !== currentOrgHandle) {
      return redirectUserThroughHandle(context.ctx, context.router.asPath, currentOrgHandle);
    }
    return false;
  }
  const currentCustomerHandle = resolvedCustomer?.customerOrgHandle.handle;
  if (resolvedCustomer && redirectUrl) {
    const resolvedRedirectUrl = updateUrlQueryParams(!customerEventId ? {} : {
      [NOVEL_PASS_ATTRIBUTION_PARAM]: customerEventId
    }, `${redirectUrl}${qsHashString}`);
    return redirectUser(context.ctx, getValidUrl(isSuperfiliateLink || !multiPassUrl ? resolvedRedirectUrl : multiPassUrl));
  } else if (resolvedCustomer && isLoggedOutRoute || isOnNotFoundPath) {
    if (currentCustomerHandle) {
      return redirectUserThroughHandle(context.ctx, `/${qsStringify(context.router.query, {
        addQueryPrefix: true
      })}`, currentCustomerHandle);
    } else {
      return redirectUser(context.ctx, `/${qsStringify(context.router.query, {
        addQueryPrefix: true
      })}`);
    }
  } else if (handle && currentCustomerHandle && handle !== currentCustomerHandle) {
    return redirectUserThroughHandle(context.ctx, context.router.asPath, currentCustomerHandle);
  }
  return false;
}