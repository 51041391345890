import type { ParamsDictionary } from "express-serve-static-core";
import { stringify as qsStringify } from "qs";
import cloneDeep from "lodash/cloneDeep";
import isPlainObject from "lodash/isPlainObject";
import merge from "lodash/merge";

import { failureResToError, SurfaceableError } from "@novel/shared/utils/SurfaceableError";

import { AppEnv, AppEnvEnum } from "./env";

export function requestFailureStr(
    url: string,
    status: number,
    statusText?: string,
    bodyText?: string,
): string {
    return `request failed to ${url} | ${status}${statusText ? `: ${statusText}` : ""}${
        bodyText ? `: ${bodyText}` : ""
    }`;
}

export type ResType = "success" | "error";

interface ResWrapper {
    type: ResType;
}

interface ParsedResError {
    status: number;
    error: Error;
}

export interface SuccessRes<T> extends ResWrapper {
    type: "success";
    body: T;
    headers?: { [key: string]: string };
}

export interface FailureRes extends ParsedResError, ResWrapper {
    type: "error";
    meta?: {
        verbose: boolean;
        displayAsError: boolean;
    };
}

export type AppReqResponse<ResBody> = SuccessRes<ResBody> | FailureRes;

const defaultConfig: RequestInit = {
    credentials: "same-origin",
    referrerPolicy: "origin-when-cross-origin",
    mode: "cors",
    cache: "default",
};

function restReq<
    ReqBody extends {} | undefined,
    ResBody,
    ReqParams extends ParamsDictionary = {},
    ReqQuery extends {} = {},
>(
    endpoint: string,
    {
        params,
        query,
        retryCount = 0,
        ...requestConfig
    }: TypedBodyRequestInit<ReqBody, ReqParams, ReqQuery> & { retryCount?: number } = {},
): Promise<AppReqResponse<ResBody>> {
    // handling attaching of request body
    const requestConfigWithFinalizedBody: RequestInit = {
        ...defaultConfig,
        ...requestConfig,
    };
    if (requestConfig.reqBody) {
        if (isPlainObject(requestConfig.reqBody)) {
            requestConfigWithFinalizedBody.headers = {
                ...requestConfigWithFinalizedBody.headers,
                "Content-Type": "application/json", // this is needed for Express to know how to parse
            };
            requestConfigWithFinalizedBody.body = JSON.stringify(requestConfig.reqBody);
        } else if (typeof requestConfig.reqBody === "string") {
            requestConfigWithFinalizedBody.headers = {
                ...requestConfigWithFinalizedBody.headers,
                "Content-Type": "text/plain", // this is needed for Express to know how to parse
            };
            requestConfigWithFinalizedBody.body = requestConfig.reqBody;
        } else {
            // confused as to why I need to cheat here... no big deal though
            requestConfigWithFinalizedBody.body = requestConfig.reqBody as any;
        }
    }

    // handle applying params
    let endpointWithParams: string;
    if (!params) {
        endpointWithParams = endpoint;
    } else {
        endpointWithParams = Object.keys(params).reduce((accum: string, paramName: string) => {
            if (params) {
                return accum.replace(
                    `:${paramName}`,
                    encodeURIComponent(params[paramName]) as string,
                );
            }
            return accum;
        }, endpoint);
    }

    // handle applying query string
    let endpointWithQueryString: string;
    if (!query || Object.keys(query).length === 0) {
        endpointWithQueryString = endpointWithParams;
    } else {
        endpointWithQueryString = `${endpointWithParams}${qsStringify(query, {
            addQueryPrefix: true,
        })}`;
    }

    return fetch(endpointWithQueryString, {
        ...requestConfigWithFinalizedBody,
    })
        .then((res) => {
            if (res.status >= 400) {
                return res
                    .text()
                    .then(async (errorText) => {
                        let parsedJson: ParsedResError | undefined;
                        try {
                            parsedJson = JSON.parse(errorText) as ParsedResError;
                        } catch (e) {
                            // should never happen, but just in case
                            if (typeof errorText === "object") {
                                parsedJson = errorText;
                            }
                        }

                        // pass off to failure handling
                        const status = parsedJson?.status || res.status;

                        const getReasonPhrase = await import("http-status-codes").then(
                            (httpStatusCodes) => httpStatusCodes.getReasonPhrase,
                        );

                        const message =
                            (parsedJson as any)?.message ||
                            parsedJson?.error?.message ||
                            (parsedJson as any)?.errors ||
                            (parsedJson as any)?.messages ||
                            (status && getReasonPhrase(status)) ||
                            errorText;

                        const resolvedMessage =
                            typeof message !== "string" ? JSON.stringify(message) : message;

                        return Promise.reject({
                            status,
                            message: resolvedMessage,
                            verbose: !!(parsedJson as any)?.meta?.verbose,
                            displayAsError: !!(parsedJson as any)?.meta?.displayAsError,
                        });
                    })
                    .catch((err) => {
                        // sloppy but everything else screwed up Typescript compiler
                        if (err.message && err.status) {
                            return Promise.reject({
                                status: err.status,
                                message: err.message,
                                verbose: !!err?.verbose || !!err?.meta?.verbose,
                                displayAsError:
                                    !!err?.displayAsError || !!err?.meta?.displayAsError,
                            });
                        }
                        return Promise.reject(res);
                    });
            }

            const contentType = res.headers?.get("content-type");
            if (contentType) {
                if (contentType.includes("application/json")) {
                    if (res.headers.get("content-length") === "0") {
                        return {
                            type: "success",
                            body: undefined as unknown as ResBody,
                        } as SuccessRes<ResBody>;
                    }

                    return res.json().then((responseJson) => {
                        if (responseJson.success === false) {
                            const castedResponseJson = responseJson as FailureRes;
                            return {
                                type: "error",
                                status: castedResponseJson.status,
                                error: failureResToError(castedResponseJson),
                            } as FailureRes;
                        }

                        return {
                            type: "success",
                            body: responseJson as unknown as ResBody,
                        } as SuccessRes<ResBody>;
                    });
                } else if (
                    contentType.includes("application/text") ||
                    contentType.includes("text/plain")
                ) {
                    return res.text().then(
                        (responseText) =>
                            ({
                                type: "success",
                                body: responseText as unknown as ResBody,
                            }) as SuccessRes<ResBody>,
                    );
                }
            }

            if (contentType) {
                // retry with accept headers set when we get signed-exchange content-type (can happen server-side with cloudflare cache)
                if (contentType.includes("application/signed-exchange") && retryCount === 0) {
                    return restReq<ReqBody, ResBody, ReqParams, ReqQuery>(endpointWithQueryString, {
                        ...requestConfig,
                        retryCount: retryCount + 1,
                        headers: {
                            ...requestConfig.headers,
                            Accept: "application/json,text/html,text/plain",
                        },
                    });
                }

                const errorContentPromise = contentType.includes("text/html")
                    ? res.text()
                    : Promise.resolve("");
                return errorContentPromise.then((errorContent) => {
                    const errorMessage = `unhandled content-type header in response from ${endpointWithQueryString}, content-type: ${contentType}${
                        errorContent ? `\n\n${errorContent}` : ""
                    }`;
                    // tslint:disable-next-line: no-console
                    console.error(errorMessage);
                    return Promise.reject({
                        status: 417, // Status code for Expectation Failed
                        message: errorMessage,
                    });
                });
            }

            return {
                type: "success",
                body: undefined as unknown as ResBody,
                headers: Array.from(res.headers.entries()).reduce(
                    (acc, [k, v]) => ({ ...acc, [k]: v }),
                    {} as { [key: string]: string },
                ),
            } as SuccessRes<ResBody>;
        })
        .catch((errorRes) => {
            if (AppEnv !== AppEnvEnum.production) {
                // tslint:disable-next-line: no-console
                console.error(
                    requestFailureStr(
                        endpointWithQueryString,
                        errorRes?.status,
                        errorRes?.statusText,
                        typeof errorRes === "object"
                            ? JSON.stringify(errorRes)
                            : errorRes?.toString(),
                    ),
                );
            }
            const message =
                ((errorRes?.message ||
                    (() => {
                        try {
                            return JSON.stringify(errorRes);
                        } catch (e: any) {
                            return errorRes?.toString?.();
                        }
                    })()) as string) || "";
            const status = parseInt(errorRes?.status, 10) || 400;

            const error = errorRes?.verbose
                ? new SurfaceableError(message, !!errorRes?.displayAsError)
                : new Error(message);
            (error as any).status = status; // used by error middleware
            if (errorRes.stack) {
                error.stack = errorRes.stack;
            }

            return {
                type: "error" as ResType,
                status,
                error,
            } as FailureRes;
        });
}

function getReq<ResBody, ReqParams extends ParamsDictionary = {}, ReqQuery extends {} = {}>(
    endpoint: string,
    requestConfig: TypedBodyRequestNoReqBodyInit<ReqParams, ReqQuery> = {},
) {
    return restReq<undefined, ResBody, ReqParams, ReqQuery>(endpoint, {
        ...requestConfig,
        method: "GET",
    });
}

function putReq<
    ReqBody extends {} | undefined,
    ResBody,
    ReqParams extends ParamsDictionary = {},
    ReqQuery extends {} = {},
>(endpoint: string, requestConfig: TypedBodyRequestInit<ReqBody, ReqParams, ReqQuery> = {}) {
    return restReq<ReqBody, ResBody, ReqParams, ReqQuery>(endpoint, {
        ...requestConfig,
        method: "PUT",
    });
}

function postReq<
    ReqBody extends {} | undefined,
    ResBody,
    ReqParams extends ParamsDictionary = {},
    ReqQuery extends {} = {},
>(endpoint: string, requestConfig: TypedBodyRequestInit<ReqBody, ReqParams, ReqQuery> = {}) {
    return restReq<ReqBody, ResBody, ReqParams, ReqQuery>(endpoint, {
        ...requestConfig,
        method: "POST",
    });
}

function patchReq<
    ReqBody extends {} | undefined,
    ResBody,
    ReqParams extends ParamsDictionary = {},
    ReqQuery extends {} = {},
>(endpoint: string, requestConfig: TypedBodyRequestInit<ReqBody, ReqParams, ReqQuery> = {}) {
    return restReq<ReqBody, ResBody, ReqParams, ReqQuery>(endpoint, {
        ...requestConfig,
        method: "PATCH",
    });
}

function deleteReq<ResBody, ReqParams extends ParamsDictionary = {}, ReqQuery extends {} = {}>(
    endpoint: string,
    requestConfig: TypedBodyRequestNoReqBodyInit<ReqParams, ReqQuery> = {},
) {
    return restReq<undefined, ResBody, ReqParams, ReqQuery>(endpoint, {
        ...requestConfig,
        method: "DELETE",
    });
}

export type ReqMiddleware = <
    ReqBody extends {} | undefined,
    ReqParams extends ParamsDictionary,
    ReqQuery extends {},
>(
    endpoint: string,
    requestConfig: TypedBodyRequestInit<ReqBody, ReqParams, ReqQuery>,
) => TypedBodyRequestInit<ReqBody, ReqParams, ReqQuery>;

export class RestReq {
    constructor(
        prefix = "",
        requestConfig: TypedBodyRequestInit = {},
        middlewares: ReqMiddleware[] = [],
    ) {
        this.prefix = prefix;
        this.requestConfig = requestConfig;
        this.middlewares = middlewares;
    }

    private readonly middlewares: ReqMiddleware[];

    private readonly requestConfig: RequestInit;

    readonly prefix: string;

    getFinalRequestConfig = <
        ReqBody extends {} | undefined,
        ReqParams extends ParamsDictionary,
        ReqQuery extends {},
    >(
        endpoint: string,
        requestConfig: TypedBodyRequestInit<ReqBody, ReqParams, ReqQuery> = {},
    ) =>
        this.middlewares.reduce(
            (
                accum: TypedBodyRequestInit<ReqBody, ReqParams, ReqQuery>,
                currentMiddleware: ReqMiddleware,
            ) => currentMiddleware(endpoint, accum),
            merge(cloneDeep(this.requestConfig), requestConfig),
        );

    getReq<ResBody, ReqParams extends ParamsDictionary = {}, ReqQuery extends {} = {}>(
        endpoint: string,
        requestConfig: TypedBodyRequestNoReqBodyInit<ReqParams, ReqQuery> = {},
    ) {
        return getReq<ResBody, ReqParams, ReqQuery>(
            `${this.prefix}${endpoint}`,
            this.getFinalRequestConfig(endpoint, requestConfig),
        );
    }

    putReq<
        ReqBody extends {} | undefined,
        ResBody,
        ReqParams extends ParamsDictionary = {},
        ReqQuery extends {} = {},
    >(endpoint: string, requestConfig: TypedBodyRequestInit<ReqBody, ReqParams, ReqQuery> = {}) {
        return putReq<ReqBody, ResBody, ReqParams, ReqQuery>(
            `${this.prefix}${endpoint}`,
            this.getFinalRequestConfig(endpoint, requestConfig),
        );
    }

    postReq<
        ReqBody extends {} | undefined,
        ResBody,
        ReqParams extends ParamsDictionary = {},
        ReqQuery extends {} = {},
    >(endpoint: string, requestConfig: TypedBodyRequestInit<ReqBody, ReqParams, ReqQuery> = {}) {
        return postReq<ReqBody, ResBody, ReqParams, ReqQuery>(
            `${this.prefix}${endpoint}`,
            this.getFinalRequestConfig(endpoint, requestConfig),
        );
    }

    patchReq<
        ReqBody extends {} | undefined,
        ResBody,
        ReqParams extends ParamsDictionary = {},
        ReqQuery extends {} = {},
    >(endpoint: string, requestConfig: TypedBodyRequestInit<ReqBody, ReqParams, ReqQuery> = {}) {
        return patchReq<ReqBody, ResBody, ReqParams, ReqQuery>(
            `${this.prefix}${endpoint}`,
            this.getFinalRequestConfig(endpoint, requestConfig),
        );
    }

    deleteReq<ResBody, ReqParams extends ParamsDictionary = {}, ReqQuery extends {} = {}>(
        endpoint: string,
        requestConfig: TypedBodyRequestNoReqBodyInit<ReqParams, ReqQuery> = {},
    ) {
        return deleteReq<ResBody, ReqParams, ReqQuery>(
            `${this.prefix}${endpoint}`,
            this.getFinalRequestConfig(endpoint, requestConfig),
        );
    }
}

export const untypedReq = new RestReq();

export interface TypedBodyRequestInit<
    ReqBody extends {} | undefined = undefined,
    ReqParams extends ParamsDictionary = {},
    ReqQuery extends {} = {},
> extends RequestInit {
    reqBody?: ReqBody;
    params?: ReqParams;
    query?: ReqQuery;
    body?: undefined;
}

export type TypedBodyRequestNoReqBodyInit<
    ReqParams extends ParamsDictionary = {},
    ReqQuery extends {} = {},
> = TypedBodyRequestInit<undefined, ReqParams, ReqQuery>;
