/**
 * Adds rejection handling for a regular promise. This way we prevent unhandled promise
 * rejection if the promise is not awaited. Typical use case is when we start several
 * async operations in parallel but don't necessarily need results of all of them:
 * ================
 * const promise1 = asyncFunction1();
 * const promise2 = asyncFunction2();
 *
 * const result1 = await promise1;
 * if (result1 === something) { return; } // And don't care about promise2
 * const result2 = await promise2;
 * ================
 *
 * The problem of the code above is that if promise1 is rejected, then the promise2 will
 * go as unhandled rejection. Unhandled rejection is kinda fatal error and we should not
 * allow them to happen.
 * In backend we are mitigating this by handling the unhandledRejection event (which we
 * shouldn't do, because it can swallow critical errors that should normally restart the
 * whole process).
 * On frontend promise2 rejection will be caught and reported in Sentry which we do not
 * want as well.
 */
export class SafePromise<T> implements PromiseLike<T> {
    readonly wrappedPromise: Promise<void>;

    readonly startTime: Date;

    succeeded: boolean | undefined;

    value: T | undefined;

    error: any;

    constructor(promise: Promise<T>, logError = true) {
        // Wrap the original promise with catch block to prevent unhandled promise rejection
        this.startTime = new Date();
        this.wrappedPromise = promise
            .then((value) => {
                this.succeeded = true;
                this.value = value;
            })
            .catch((error) => {
                this.succeeded = false;
                this.error = error;

                // To capture loose error information if we don't await the SafePromise instance
                if (logError) {
                    console.error(
                        "SafePromise rejection. Started at: %s. Error: %s",
                        this.startTime.toISOString(),
                        error,
                    );
                }
            });
    }

    /**
     * Unwraps the promise with rejection handling back into the regular promise.
     */
    async unwrap(): Promise<T> {
        await this.wrappedPromise;
        if (this.succeeded) {
            return this.value as T;
        } else {
            throw this.error;
        }
    }

    /**
     * By implementing "then" from PromiseLike interface we can await SafePromise directly as if
     * it was the regular promise.
     */
    then<TResult1 = T, TResult2 = never>(
        onfulfilled?: ((value: T) => TResult1 | PromiseLike<TResult1>) | null,
        onrejected?: ((reason: any) => TResult2 | PromiseLike<TResult2>) | null,
    ): PromiseLike<TResult1 | TResult2> {
        return this.unwrap().then(onfulfilled, onrejected);
    }
}
