import { AsyncCustomerPassDispatch } from "@customer-pass/redux/customerPassTypedRedux";

export const asyncDispatch: AsyncCustomerPassDispatch = async function asyncDispatch(
    promise,
    onLoading,
    onError,
    onSuccess,
) {
    // this must complete before proceeding
    await onLoading();

    const res = await promise; // not handling failures because that should be impossible per
    switch (res.type) {
        case "error": {
            const result = onError({
                status: res.status,
                error: res.error,
            });

            if (res.error) {
                throw res.error;
            }

            return result;
        }
        case "success": {
            return onSuccess(res.body);
        }
    }
};
