"use strict";
var _a, _b;
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReleaseName = exports.AppEnv = exports.NodeEnv = exports.AppEnvEnum = exports.NodeEnvEnum = void 0;
var NodeEnvEnum;
(function (NodeEnvEnum) {
    NodeEnvEnum["development"] = "development";
    NodeEnvEnum["production"] = "production";
    NodeEnvEnum["test"] = "test";
})(NodeEnvEnum || (exports.NodeEnvEnum = NodeEnvEnum = {}));
var AppEnvEnum;
(function (AppEnvEnum) {
    AppEnvEnum["development"] = "development";
    AppEnvEnum["test"] = "test";
    AppEnvEnum["staging"] = "staging";
    AppEnvEnum["production"] = "production";
})(AppEnvEnum || (exports.AppEnvEnum = AppEnvEnum = {}));
exports.NodeEnv = process.env.NODE_ENV === "production"
    ? NodeEnvEnum.production
    : process.env.NODE_ENV === "test"
        ? NodeEnvEnum.test
        : NodeEnvEnum.development;
var IS_STAGING_GITLAB_STAGE = process.env.GITLAB_ENVIRONMENT_NAME === AppEnvEnum.staging;
var IS_STAGING_FRONTEND = typeof window !== "undefined" &&
    typeof ((_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.hostname) !== "undefined" &&
    (window.location.hostname.endsWith("staging.novel.com") || // for pass and vendor portal staging
        [
            "novel-staging.myshopify.com",
            "akhils-test-store.myshopify.com",
            "novel-staging-plus.myshopify.com",
        ].includes(window.location.hostname)); // for staging Shopify store
// the NODE_ENV is used internally by many libraries (with certain optimizations done for "production")
// therefore we need a separate variable to capture particularly the "staging" case where we will want to fully replicate
// prod by setting NODE_ENV = "production" for the build process
exports.AppEnv = IS_STAGING_GITLAB_STAGE || IS_STAGING_FRONTEND
    ? AppEnvEnum.staging
    : (_b = exports.NodeEnv) !== null && _b !== void 0 ? _b : AppEnvEnum.development;
// If no valid tag name is provded, we should fallback to `YYYY-MM-DD-HH` in order to properly manage an id
// this is useful for stuff like Sentry, DataDog, FullStory, etc
var releaseTime = process.env.NEXT_PUBLIC_BUILD_TIMESTAMP
    ? new Date(+process.env.NEXT_PUBLIC_BUILD_TIMESTAMP)
    : new Date();
var releaseNameFromEnv = process.env.NEXT_PUBLIC_COMMIT_TAG ||
    process.env.COMMIT_TAG ||
    process.env.NEXT_PUBLIC_COMMIT_SHA ||
    process.env.COMMIT_SHA;
exports.ReleaseName = releaseNameFromEnv !== "undefined" && releaseNameFromEnv !== undefined
    ? releaseNameFromEnv
    : "".concat(releaseTime.toJSON().slice(0, 10), "-").concat(releaseTime.getHours());
