import { AppEnv, AppEnvEnum } from "@novel/shared/utils/env";
import { applyMiddleware, compose, createStore, StoreCreator } from "redux";
import thunk from "redux-thunk";
import { rootReducer, initialState } from "./reducers";
import { NovelCustomerPassStore } from "./customerPassTypedRedux";
import { createWrapper } from "next-redux-wrapper";

function initStore(): NovelCustomerPassStore {
    const composeEnhancers: StoreCreator =
        (typeof window !== "undefined" &&
            (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
            (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__()) ||
        compose;
    const store = createStore(
        rootReducer,
        initialState,
        // type is too tricky to figure out
        compose(applyMiddleware(thunk), composeEnhancers as any),
    );

    // for webpack hot reloading (idk if that's happening)
    if (AppEnv === AppEnvEnum.development) {
        if ((module as any)?.hot) {
            // Enable Webpack hot module replacement for reducers
            (module as any)?.hot.accept("./reducers", () => {
                // eslint-disable-next-line @typescript-eslint/no-require-imports
                const nextReducer = require("./reducers");
                store.replaceReducer(nextReducer);
            });
        }
    }

    return store;
}

// export an assembled wrapper
export const wrapper = createWrapper<NovelCustomerPassStore>(() => initStore());
