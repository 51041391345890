import { useNovelCustomerPassSelector } from "@customer-pass/redux/reduxHooks";
import * as Sentry from "@sentry/browser";
import React, { ReactNode, useEffect } from "react";
import { useInitAndroidEffect } from "@customer-pass/redux/actionCreators/androidDevice";
import { loadNovelIntercom } from "@novel/shared/utils/loadNovelIntercom";
import { useWalletPassInstallFlow } from "@customer-pass/redux/reducers/walletPassInstallFlow";
interface Props {
  readonly children?: ReactNode;
  readonly isSuperUserRoute?: boolean;
  readonly isPublicRoute?: boolean;
  readonly isLoggedOutRoute?: boolean;
}
export function InitContainer({
  children
}: Props): JSX.Element {
  const walletPassInstallFlow = useWalletPassInstallFlow();
  const resolvedOrg = useNovelCustomerPassSelector(state => state.orgData.resolvedOrg);
  const resolvedCustomer = useNovelCustomerPassSelector(state => state.auth.resolvedCustomer);
  useInitAndroidEffect();
  useEffect(() => {
    loadNovelIntercom({
      email: resolvedCustomer?.novelUser.primaryEmail,
      phoneNumber: resolvedCustomer?.novelUser.mostRecentPhoneNumber,
      firstName: resolvedCustomer?.novelUser.firstName,
      lastName: resolvedCustomer?.novelUser.lastName,
      walletPassInstallFlow,
      orgName: resolvedOrg?.org.orgName,
      domain: resolvedOrg?.shopData?.domain || resolvedOrg?.org.orgDomain,
      myShopifyDomain: resolvedOrg?.shopData?.myShopifyDomain,
      orgCustomerEmail: resolvedOrg?.shopData?.customer_email,
      orgPartnerEmail: resolvedOrg?.shopData?.email,
      shopOwner: resolvedOrg?.shopData?.shop_owner
    });
  }, [resolvedCustomer?.novelUser, resolvedOrg?.org, resolvedOrg?.shopData, walletPassInstallFlow]);
  useEffect(() => {
    // if (!resolvedCustomer?.novelUser.isSuperUser) {
    //     if (resolvedCustomer?.novelUser && resolvedCustomer?.orgCustomer) {
    //         const { firstName, lastName, displayName, primaryEmail, ...otherFields } =
    //             resolvedCustomer.novelUser;

    //         setUpFullStory("pass", resolvedCustomer.novelUser.id, {
    //             displayName:
    //                 displayName ||
    //                 [firstName, lastName].filter(Boolean).join(" ") ||
    //                 primaryEmail,
    //             email: primaryEmail,
    //             ...otherFields,
    //             ...resolvedCustomer.orgCustomer,
    //         });
    //     } else {
    //         setUpFullStory("pass");
    //     }
    // }

    if (resolvedCustomer) {
      // If user is valid we should track him using this id and email, to track specific issues
      Sentry.setUser({
        id: resolvedCustomer.novelUser.id,
        email: resolvedCustomer.novelUser.primaryEmail
      });
    } else {
      // If user is not valid, we should remove any user information
      Sentry.configureScope(scope => scope.setUser(null));
    }
  }, [resolvedCustomer]);
  return <React.Fragment>{children}</React.Fragment>;
}