import type { PassInstallFlow } from "@novel/shared/utils/getInstallPassFlow";
import { NovelCustomerPassSubReducer } from "@customer-pass/redux/customerPassTypedRedux";
import type { FiatCurrencyCode } from "@novel/shared/utils/formatMoney";
import { useNovelCustomerPassSelector } from "@customer-pass/redux/reduxHooks";
import { IOrgCustomerRelationshipWithRewardsStatusAndTier } from "@novel/shared/interfaces/OrgCustomerRelationship";
import { IRewardsTier } from "@novel/shared/interfaces/RewardsTier";

export interface IWalletPassInstallFlowState {
    walletPassInstallFlow?: PassInstallFlow;
    isAppleDevice?: boolean;
    isChromeBrowser?: boolean;
    computedCountryCode?: string;
    computedCurrencyCode?: FiatCurrencyCode;
    passDownloadUrl?: string;
    passDownloadUrlLoading: boolean;
    resolvedReferrer?: IOrgCustomerRelationshipWithRewardsStatusAndTier;
    uponInstallRewardsTier?: IRewardsTier;
}

const initialState: IWalletPassInstallFlowState = {
    passDownloadUrlLoading: true,
};

export const passUiReducer: NovelCustomerPassSubReducer<IWalletPassInstallFlowState> = (
    state = initialState,
    action,
) => {
    switch (action.type) {
        case "SET_WALLET_PASS_INSTALL_FLOW": {
            return {
                ...state,
                walletPassInstallFlow: action.payload.walletPassInstallFlow,
                isAppleDevice: action.payload.isAppleDevice,
                isChromeBrowser: action.payload.isChromeBrowser,
                computedCountryCode: action.payload.computedCountryCode,
                computedCurrencyCode: action.payload.computedCurrencyCode,
                ...(!action.payload.passDownloadUrl
                    ? {}
                    : {
                          passDownloadUrl: action.payload.passDownloadUrl,
                          passDownloadUrlLoading: false,
                      }),
                ...(action.payload.currentWalletPass
                    ? {
                          passDownloadUrlLoading: false,
                      }
                    : {}),
                resolvedReferrer: action.payload.resolvedReferrer,
                uponInstallRewardsTier: action.payload.uponInstallRewardsTier,
            };
        }

        case "SET_PASS_DOWNLOAD_URL": {
            return {
                ...state,
                passDownloadUrl: action.payload.passDownloadUrl,
                passDownloadUrlLoading: false,
            };
        }

        default: {
            return state;
        }
    }
};

export function useWalletPassInstallFlow(): PassInstallFlow | undefined {
    const walletPassInstallFlow = useNovelCustomerPassSelector(
        (state) => state.passUi.walletPassInstallFlow,
    );

    return walletPassInstallFlow;
}

export function useGuessedPhoneOperatingSystem(): "ios" | "android" {
    const isAppleDevice = useNovelCustomerPassSelector((state) => state.passUi.isAppleDevice);
    const computedCountryCode = useNovelCustomerPassSelector(
        (state) => state.passUi.computedCountryCode,
    );

    return isAppleDevice || (computedCountryCode && isIphoneCountry(computedCountryCode))
        ? "ios"
        : "android";
}

function isIphoneCountry(computedCountryCode: string): boolean {
    // list generated by Github Copilot!!
    const countryCodesWithIphoneDominance = [
        "AU",
        "AT",
        "BE",
        "CA",
        "CH",
        "DE",
        "DK",
        "ES",
        "FI",
        "FR",
        "GB",
        "GR",
        "HK",
        "HU",
        "ID",
        "IE",
        "IT",
        "JP",
        "KR",
        "MY",
        "NL",
        "NO",
        "NZ",
        "PT",
        "SG",
        "SE",
        "TW",
        "US",
    ];
    return countryCodesWithIphoneDominance.includes(computedCountryCode);
}
