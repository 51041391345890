import { useCallback } from "react";
import { shallowEqual, useDispatch, useSelector, useStore } from "react-redux";

import { INovelCustomerPassState } from "./reducers";
import {
    NovelCustomerPassAction,
    NovelCustomerPassActionActionType,
    GeneralNovelCustomerPassDispatch,
    NovelCustomerPassStore,
} from "./customerPassTypedRedux";

export function useNovelCustomerPassStore(): NovelCustomerPassStore {
    return useStore<
        INovelCustomerPassState,
        NovelCustomerPassAction<NovelCustomerPassActionActionType>
    >();
}

export function useNovelCustomerPassDispatch(): GeneralNovelCustomerPassDispatch {
    return useDispatch<GeneralNovelCustomerPassDispatch>();
}

export function useNovelCustomerPassSelector<TSelected>(
    selector: (state: INovelCustomerPassState) => TSelected,
    equalityFn: (left: TSelected, right: TSelected) => boolean = shallowEqual, // we default to shallow equal even though redux does not now
): TSelected {
    return useSelector<INovelCustomerPassState, TSelected>(
        useCallback(selector, [selector]),
        equalityFn,
    );
}
