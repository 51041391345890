import type { FailureRes } from "@novel/shared/utils/restReq";

export function failureResToError(failureRes: FailureRes): Error {
    return failureRes.meta?.verbose
        ? new SurfaceableError(failureRes.error.message.toString())
        : new Error(failureRes.error.message.toString());
}

// a type check can be used at runtime on the error to determine whether or not it should be surfaced to the user
export class SurfaceableError extends Error {
    status?: number;

    verbose = true;

    displayAsError: boolean;

    constructor(
        data:
            | string
            | {
                  message: string;
                  stack?: string;
                  status?: number;
              },
        displayAsError = true,
    ) {
        super();
        if (typeof data === "string") {
            this.message = data;
        } else {
            this.message = data.message;
            if (data.status) {
                this.status = data.status;
            }

            // if error provided we use that error's stack to trace bugs more easily
            if (data.stack) {
                this.stack = data.stack;
            }
        }

        this.displayAsError = displayAsError;
    }
}

export function makeErrorSurfaceable(error: string | Error): SurfaceableError {
    if (isErrorSurfaceable(error)) {
        return error as SurfaceableError;
    }

    return new SurfaceableError(
        typeof error === "string"
            ? {
                  message: error,
              }
            : {
                  message: error.message,
                  stack: error.stack,
              },
    );
}

export function isErrorSurfaceable(error: Error | string): boolean {
    if (typeof error === "string") {
        return false;
    }

    return error instanceof SurfaceableError || !!(error as any)?.verbose;
}

export function isErrorDisplayedAsError(error: Error | string): boolean {
    if (typeof error === "string" || typeof (error as any)?.displayAsError !== "boolean") {
        return true;
    }

    return (error as any)?.displayAsError;
}
