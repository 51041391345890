import { combineReducers } from "redux";

import {
    NovelCustomerPassAction,
    NovelCustomerPassActionActionType,
} from "@customer-pass/redux/customerPassTypedRedux";
import { HYDRATE } from "next-redux-wrapper";

import { authReducer, IAuthReducerState } from "./auth";
import { orgDataReducer, IOrgDataReducerState } from "./organization";
import { passUiReducer, IWalletPassInstallFlowState } from "./walletPassInstallFlow";

const reducersObj = {
    auth: authReducer,
    orgData: orgDataReducer,
    passUi: passUiReducer,
};

// object ref ensures equality check failure
const impossibleToEqual = {};

// default values for each reducer
export const initialState: INovelCustomerPassState = Object.keys(reducersObj).reduce(
    (accum: any, key: string) => {
        accum[key] = (reducersObj as any)[key](void 0, {
            type: impossibleToEqual,
        });
        return accum;
    },
    {},
) as any;

const combinedReducers = combineReducers<
    INovelCustomerPassState,
    NovelCustomerPassAction<NovelCustomerPassActionActionType>
>(reducersObj);
export const rootReducer: typeof combinedReducers = function rootReducer(state, action) {
    if ((action as any).type === HYDRATE) {
        const nextState = {
            ...state,
            ...(action as any).payload,
        };
        if ((state as any).count) {
            nextState.count = (state as any).count; // preserve count value on client side navigation
        }
        return nextState;
    }
    return combinedReducers(state, action);
};

export interface INovelCustomerPassState {
    auth: IAuthReducerState;
    orgData: IOrgDataReducerState;
    passUi: IWalletPassInstallFlowState;
}
