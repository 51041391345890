export function updateUrlQueryParams(
    paramsObj: {
        [key: string]: string[] | string | number | boolean | null | undefined;
    },
    urlStr: string = window.location.href,
): string {
    if (Object.keys(paramsObj).length === 0) {
        return urlStr;
    }

    const url = new URL(urlStr);

    Object.entries(paramsObj).forEach(([key, value]) => {
        if (value !== null && value !== undefined && value !== "null" && value !== "undefined") {
            url.searchParams.set(key, value.toString());
        }
    });

    return url.toString();
}

export function removeUrlQueryParams(
    paramsToRemove: string[],
    urlStr: string = window.location.href,
): string {
    if (paramsToRemove.length === 0) {
        return urlStr;
    }

    const url = new URL(urlStr);

    paramsToRemove.forEach((key) => {
        url.searchParams.delete(key);
    });

    return url.toString();
}

export function applyUrlQueryParamsUpdateToWindow(
    paramsUpdateObj: {
        [key: string]: string | number | boolean | null | undefined;
    },
    paramsToRemove: string[] = [],
): void {
    if (typeof window === "undefined") {
        return;
    }

    window.history.replaceState(
        "",
        "",
        removeUrlQueryParams(paramsToRemove, updateUrlQueryParams(paramsUpdateObj)),
    );
}
