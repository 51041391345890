import React, { ErrorInfo, Suspense, lazy } from "react";
import { createPortal } from "react-dom";
import type * as Sentry from "@sentry/nextjs";
import { isErrorDisplayedAsError, isErrorSurfaceable } from "@novel/shared/utils/SurfaceableError";
import { initToastsRootRenderElement } from "@novel/shared/utils/getRootRenderElement";
import { onReady } from "@novel/shared/utils/onReady";
import { AppEnv, AppEnvEnum } from "@novel/shared/utils/env";
import { retryWithBackoff } from "@novel/shared/utils/retryWithBackoff";
interface SentryCapturer {
  captureException(exception: any, hint?: Sentry.EventHint): string;
  captureMessage(message: string, level?: Sentry.Severity | Sentry.SeverityLevel, hint?: Sentry.EventHint): string;
}
interface GlobalErrorBoundaryProps {
  readonly noToast?: boolean;
  readonly isBlockchainApp?: boolean;
  readonly handleError?: (error: Error, errorInfo?: ErrorInfo) => void;
  readonly sentryContext?: Promise<SentryCapturer> | SentryCapturer;
  readonly children?: React.ReactNode;
}
export const globalErrorBoundaryUtils: {
  throwPseudoError: (error: Error, errorInfo?: ErrorInfo) => void;
} = {
  throwPseudoError: (error: Error, errorInfo?: ErrorInfo) => {}
};
export class GlobalErrorBoundary extends React.Component<GlobalErrorBoundaryProps, {
  isDocumentReady: boolean;
}> {
  state = {
    isDocumentReady: false
  };
  sendToSentry = async (error: Error, isSurfaceable: boolean): Promise<void> => {
    const sentryContext = await this.props.sentryContext;
    if (sentryContext) {
      if (isSurfaceable) {
        sentryContext.captureMessage(error.message, "info", {
          originalException: error
        });
      } else {
        sentryContext.captureException(error, {
          originalException: error
        });
      }
    }
  };
  handleError = async (error: Error, errorInfo?: ErrorInfo): Promise<void> => {
    // some errors thrown by libraries are not actual error objects, if so we convert them
    // to ensure downstream interoperability with Sentry etc.
    if (!(error instanceof Error) && (error as any).message) {
      const initialError = error;
      error = new Error((initialError as any).message);
      Object.assign(error, initialError);
      if (errorInfo && !(initialError as any).stack) {
        error.stack = errorInfo.componentStack;
      }
    }
    if (error?.message?.includes("Minified React error #418") || error?.message?.includes("Minified React error #423") || error?.message?.includes("Minified React error #425") || error?.message?.toLowerCase().includes("hydration") || error?.message?.toLowerCase().includes("hydrating")) {
      return;
    }

    // will happen once sentry loads
    const isSurfaceable = isErrorSurfaceable(error);
    this.sendToSentry(error, isSurfaceable);
    if (!this.props.noToast) {
      // wrapping in try/catch to avoid throwing because toast not mounted
      try {
        console.error(error);
        const fallbackError = this.props.isBlockchainApp ? "The blockchain is busy! Refresh your browser and try again." : "An unexpected error occurred, please refresh your browser and try again.";
        if (!isErrorDisplayedAsError(error)) {
          const {
            infoToast
          } = await retryWithBackoff(() => import("@novel/shared/utils/toastUtils"));
          infoToast({
            content: error?.message || fallbackError
          });
        } else {
          const {
            errorToast
          } = await retryWithBackoff(() => import("@novel/shared/utils/toastUtils"));
          errorToast({
            content: error?.message && isSurfaceable ? error.message : fallbackError
          });
        }
      } catch (e) {
        console.error(e);
      }
    }
    if (this.props.handleError) {
      this.props.handleError(error, errorInfo);
    }
  };
  componentDidMount(): void {
    onReady(() => {
      this.setState({
        isDocumentReady: true
      });
    });
  }
  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    this.handleError(error, errorInfo);
  }
  static getDerivedStateFromError(error: Error): void {
    // wrapping in try/catch because of paranoia
    try {
      // log error for our debugging
      const isSurfaceable = isErrorSurfaceable(error);
      (typeof window === "undefined" ? console.log : console.warn)(isSurfaceable, typeof error === "string" ? error : error?.message || "<null>");
      // eslint-disable-next-line no-empty
    } catch (e: any) {}

    // emergency logic to unmount the app to avoid infinite loop on storefront
    if (typeof window !== "undefined" && (window as any)?.__n__u__) {
      if (error?.stack?.includes("areHookInputsEqual")) {
        console.log(`CRITICAL ERROR: unmounting${error?.message ? ` ${error?.message}` : ""}`);
        setTimeout(() => {
          try {
            (window as any).__n__u__();
            // eslint-disable-next-line no-empty
          } catch (e: any) {}
        });
      }
    }
  }
  render(): JSX.Element {
    globalErrorBoundaryUtils.throwPseudoError = this.handleError;
    return <React.Fragment>
                {this.props.children}
                {!this.state.isDocumentReady || this.props.noToast ? null : createPortal(<ToastContainerWrapper />, initToastsRootRenderElement())}
                {/* disabling error overlay */}
                {AppEnv === AppEnvEnum.development && <style>{`
                        nextjs-portal {
                            display: none;
                        }
                    `}</style>}
            </React.Fragment>;
  }
}
const ToastContainerLazy = lazy(() => retryWithBackoff(() => import("react-toastify").then(module => ({
  default: module.ToastContainer
}))));
function ToastContainerWrapper() {
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <Suspense fallback={<React.Fragment />}>
            <ToastContainerLazy theme="dark" className="novel-toast-container" />
        </Suspense>
  );
}