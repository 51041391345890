import { paths } from "../__generated__/GeneratedCustomerRouteTypes";
import {
    ReqMiddleware,
    RestReq,
    TypedBodyRequestInit,
    TypedBodyRequestNoReqBodyInit,
} from "../utils/restReq";

import type {
    DeleteParams,
    DeleteQueryParams,
    DeleteResponse,
    GetParams,
    GetQueryParams,
    GetResponse,
    PatchParams,
    PatchQueryParams,
    PatchRequest,
    PatchResponse,
    PostParams,
    PostQueryParams,
    PostRequest,
    PostResponse,
    PutParams,
    PutQueryParams,
    PutRequest,
    PutResponse,
} from "./Http";

/*
 * Typed API
 */
export class TypedCustomerApi extends RestReq {
    // need to provide base host for server side requests, client-side
    // requests need to match the host because we are using cookies for
    // auth and therefore need "same-origin" credentials
    constructor(baseHost = "", additionalMiddleWares: ReqMiddleware[]) {
        super(baseHost, {}, [
            (endpoint, requestConfig) => ({
                ...requestConfig,
                // we need to include the authentication cookie
                credentials: "same-origin",
            }),
            ...additionalMiddleWares,
        ]);
    }

    getReq<Endpoint extends keyof paths>(
        endpoint: Endpoint,
        requestConfig: TypedBodyRequestNoReqBodyInit<
            GetParams<Endpoint>,
            GetQueryParams<Endpoint>
        > = {},
    ) {
        return super.getReq<GetResponse<Endpoint>, GetParams<Endpoint>, GetQueryParams<Endpoint>>(
            endpoint,
            requestConfig,
        );
    }

    postReq<Endpoint extends keyof paths>(
        endpoint: Endpoint,
        requestConfig: TypedBodyRequestInit<
            PostRequest<Endpoint>,
            PostParams<Endpoint>,
            PostQueryParams<Endpoint>
        > = {},
    ) {
        return super.postReq<
            PostRequest<Endpoint>,
            PostResponse<Endpoint>,
            PostParams<Endpoint>,
            PostQueryParams<Endpoint>
        >(endpoint, requestConfig);
    }

    patchReq<Endpoint extends keyof paths>(
        endpoint: Endpoint,
        requestConfig: TypedBodyRequestInit<
            PatchRequest<Endpoint>,
            PatchParams<Endpoint>,
            PatchQueryParams<Endpoint>
        > = {},
    ) {
        return super.patchReq<
            PatchRequest<Endpoint>,
            PatchResponse<Endpoint>,
            PatchParams<Endpoint>,
            PatchQueryParams<Endpoint>
        >(endpoint, requestConfig);
    }

    putReq<Endpoint extends keyof paths>(
        endpoint: Endpoint,
        requestConfig: TypedBodyRequestInit<
            PutRequest<Endpoint>,
            PutParams<Endpoint>,
            PutQueryParams<Endpoint>
        > = {},
    ) {
        return super.postReq<
            PutRequest<Endpoint>,
            PutResponse<Endpoint>,
            PutParams<Endpoint>,
            PutQueryParams<Endpoint>
        >(endpoint, requestConfig);
    }

    deleteReq<Endpoint extends keyof paths>(
        endpoint: Endpoint,
        requestConfig: TypedBodyRequestNoReqBodyInit<
            DeleteParams<Endpoint>,
            DeleteQueryParams<Endpoint>
        > = {},
    ) {
        return super.deleteReq<
            DeleteResponse<Endpoint>,
            DeleteParams<Endpoint>,
            DeleteQueryParams<Endpoint>
        >(endpoint, requestConfig);
    }
}
