import { AppEnv, ReleaseName } from "@novel/shared/utils/env";
import type { BrowserOptions } from "@sentry/browser";

export const sentryBaseConfig: BrowserOptions = {
    release: ReleaseName,
    environment: AppEnv,
    // Uncomment for local testing
    enabled: true, // AppEnv === AppEnvEnum.production || AppEnv === AppEnvEnum.staging,
    // Allow just 1% of the sampling errors to profile
    tracesSampleRate: 0.01,
    // Filtering errors based on https://gist.github.com/impressiver/5092952
    ignoreErrors: [
        // Random plugins/extensions
        "top.GLOBALS",
        // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
        "originalCreateNotification",
        "canvas.contentDocument",
        "MyApp_RemoveAllHighlights",
        "http://tt.epicplay.com",
        "Can't find variable: ZiteReader",
        "jigsaw is not defined",
        "ComboSearch is not defined",
        "http://loading.retry.widdit.com/",
        "atomicFindClose",
        // Facebook borked
        "fb_xd_fragment",
        // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
        // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
        "bmi_SafeAddOnload",
        "EBCallBackMessageReceived",
        // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
        "conduitPage",
        // Generic error code from errors outside the security sandbox
        // You can delete this if using raven.js > 1.0, which ignores these automatically.
        "Script error.",
        // Error while trying to set a cookie on a local file instead of a local server
        /SecurityError: DOM Exception 18$/,
    ],
    // Filtering urls based on https://gist.github.com/impressiver/5092952
    denyUrls: [
        // Facebook flakiness
        /graph\.facebook\.com/i,
        // Facebook blocked
        /connect\.facebook\.net\/en_US\/all\.js/i,
        // Woopra flakiness
        /eatdifferent\.com\.woopra-ns\.com/i,
        /static\.woopra\.com\/js\/woopra\.js/i,
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
        // Other plugins
        /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
        /webappstoolbarba\.texthelp\.com\//i,
        /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
    ],
    // Filtering specific errors to avoid pollute the service
    beforeSend(event, hint) {
        const { error, response } = (hint?.originalException || {}) as any;
        const message = error?.message || "";
        const status = response?.status || "";

        // If the error is a promise, we should ignore if the status is a 404
        // This is not precisely an exception we want to track
        if (status === 404) return null;

        // The site has been deployed, the assets are not valid anymore and the site should be reloaded
        // if (/ChunkLoadError/.test(message)) return null;

        return event;
    },
};
